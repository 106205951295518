import React, { useRef, useEffect, useState, useContext, ReactSVGElement } from "react";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { usePopper } from 'react-popper';
import { CSVLink } from "react-csv";

// import {useAsync } from "react-async";
import { MapContainer, TileLayer, Popup, Marker, useMap, useMapEvents } from 'react-leaflet';
import L, { LatLngBoundsLiteral, LatLngBounds } from 'leaflet';
import { useAsync, useAsyncCallback } from "react-async-hook";
import { Link } from 'react-router-dom';

import { MdOutlineClear } from "react-icons/md";

import "./styles.css";

import { Header } from '../../branding/Header';
import { Footer } from '../../branding/Footer';
import { IntroStateText } from '../../branding/IntroStateText';

import Charge from '../../charge/Charge/Charge';
import Charges from '../../charge/Charges/Charges';

import { UseOutsideClick } from '../../UseOutsideClick/UseOutsideClick';


import { ISearchInterface, IMatchInterface, IFacilityInterface, IItemInterface, IChargeInterface, IMapInterface } from '../../../@types/context';
import { SearchContext } from '../../context/SearchContext';
import { MatchContext } from '../../context/MatchContext';
import { MapContext } from '../../context/MapContext';
import AnalyticsService from '../../../services/analytics.service';
import ServerService from '../../../services/server.service';
import { v4 as uuidv4 } from 'uuid';
import { match } from "assert";

import UtilsService from "../../../services/utils.service";
import ConstantService from "../../../services/constant.service";
import { sensitiveHeaders } from "http2";

import { ErrorBoundary } from 'react-error-boundary';

import LoadingOverlay from 'react-loading-overlay-ts';
// import FadeLoader from 'react-spinners/FadeLoader';

const analyticsService = new AnalyticsService();
const serverService = new ServerService();

// Region information is injected by env.js (see public)
const region_name = (window as any).env.NEGOTIATED_CHARGE_REGION_NAME || '';

// Try and catch errors and recover using ErrorBoundaRY
let fallbackTriggered = false;
function fallback(args: any): any {
  const error = args['error'];
  const resetErrorBoundary = args['resetErrorBoundary'];
  //console.log('ErrorBoundary', error);
  if (!fallbackTriggered) {
    fallbackTriggered = true;
    setTimeout(() => {
      //console.log('reset!');
      fallbackTriggered = false;
      resetErrorBoundary();
    }, 100);
  }

  return null;
}

// Custom spinner to show during item, charge loading
function MySpinner ({active, children}: any) {
  return (
    <LoadingOverlay active={active} text="Searching hospital for matching items" spinner={true} styles={{
      overlay: (base: any) => ({
        ...base,
        background: 'var(--pra-blue)',
        opacity: 0.5
      })
     }} >
      {children}
    </LoadingOverlay>
  );
}


const defaultlat = 39.395;
const defaultlong = -105.2276;
const defaultzoom = 12;


interface FacilitySearchSettings {
  sessionid: string;
  requestid: string;
  error?: string;
  facilitySearch: string;
  descriptionSearch: string;
  codeSearch: string;
  facilityRetrival: string; // '', pending, fetched
};

interface FacilitySearchProps {
  tableid: string;
}

type LocationProps = {
  facilityid: string;
  recent: boolean;
  name: string;
  city: string;
  state: string;
  zip: string;
  lat: string;
  lng: string;
  cmsrating: string;
};

type ItemProps = {
  itemid: string;
  description: string;
  code: string;
  code_type: string;
  /** All codes for this item (json) */
  item_codes: string;
}


const starcss = `
width: '100px';
height: '100px';
background-size: '100px 100px';
background-color: 'red';
background-image: url('../../../icons/Pin with Stars_blue_No-rating.png');
`;

const stars0s = L.divIcon({className: 'stars0s', iconSize: undefined});
const stars0m = L.divIcon({className: 'stars0m', iconSize: undefined});
const stars0l = L.divIcon({className: 'stars0l', iconSize: undefined});

const stars1s = L.divIcon({className: 'stars1s', iconSize: undefined});
const stars1m = L.divIcon({className: 'stars1m', iconSize: undefined});
const stars1l = L.divIcon({className: 'stars1l', iconSize: undefined});

const stars2s = L.divIcon({className: 'stars2s', iconSize: undefined});
const stars2m = L.divIcon({className: 'stars2m', iconSize: undefined});
const stars2l = L.divIcon({className: 'stars2l', iconSize: undefined});

const stars3s = L.divIcon({className: 'stars3s', iconSize: undefined});  
const stars3m = L.divIcon({className: 'stars3m', iconSize: undefined}); 
const stars3l = L.divIcon({className: 'stars3l', iconSize: undefined}); 

const stars4s = L.divIcon({className: 'stars4s', iconSize: undefined});
const stars4m = L.divIcon({className: 'stars4m', iconSize: undefined});
const stars4l = L.divIcon({className: 'stars4l', iconSize: undefined});

const stars5s = L.divIcon({className: 'stars5s', iconSize: undefined});
const stars5m = L.divIcon({className: 'stars5m', iconSize: undefined});
const stars5l = L.divIcon({className: 'stars5l', iconSize: undefined});

//const stars1map = L.divIcon({className: 'stars1map', iconSize: undefined});
//const stars2map = L.divIcon({className: 'stars2map', iconSize: undefined});
//const stars3map = L.divIcon({className: 'stars3map', iconSize: undefined});
//const stars4map = L.divIcon({className: 'stars4map', iconSize: undefined});
//const stars5map = L.divIcon({className: 'stars5map', iconSize: undefined});

let currentzoom = 0;

const stars = (cmsrating: string, zoom: number): L.DivIcon => {
  switch (cmsrating) {
    case '1':
      return stars1m;
    case '2':
      return stars2m;
    case '3':
      return stars3m;
    case '4':
      return stars4m;
    case '5':
      return stars5m;
    case '0':
    default:
      return stars0m;
  }
}

// Return the stars icon or text for use in the map popup
const starsmap = (cmsrating: string): any => {
  const link = (<a target="_blank" href="https://data.cms.gov/provider-data/topics/hospitals/overall-hospital-quality-star-rating/">CMS Quality Score</a>);
  switch (cmsrating) {
    case '1':
      return (<div className="locations_dropdown-link-row"><div className="stars1map"/>&nbsp;{link}</div>);
    case '2':
      return (<div className="locations_dropdown-link-row"><div className="stars2map"/>&nbsp;{link}</div>);
    case '3':
      return (<div className="locations_dropdown-link-row"><div className="stars3map"/>&nbsp;{link}</div>);
    case '4':
      return (<div className="locations_dropdown-link-row"><div className="stars4map"/>&nbsp;{link}</div>);
    case '5':
      return (<div className="locations_dropdown-link-row"><div className="stars5map"/>&nbsp;{link}</div>);
    case '0':
    default:
      break;
  }
    return (<div className="locations_dropdown-link-row">No&nbsp;{link}</div>);
}

/*
    case '1':
      if (zoom <=6) {
        return stars1s;
      }
      else if (zoom <= 12) {
        return stars1m;
      }
      else {
        return stars1l;
      }
    case '2':
      if (zoom <=6) {
        return stars2s;
      }
      else if (zoom <= 12) {
        return stars2m;
      }
      else {
        return stars2l;
      }
    case '3':
      if (zoom <=6) {
        return stars3s;
      }
      else if (zoom <= 12) {
        return stars3m;
      }
      else {
        return stars3l;
      }
    case '4':
      if (zoom <=6) {
        return stars4s;
      }
      else if (zoom <= 12) {
        return stars4m;
      }
      else {
        return stars4l;
      }
    case '5':
      if (zoom <=6) {
        return stars5s;
      }
      else if (zoom <= 12) {
        return stars5m;
      }
      else {
        return stars5l;
      }
    case '0':
    default:
      if (zoom <=6) {
        return stars0s;
      }
      else if (zoom <= 12) {
        return stars0m;
      }
      else {
        return stars0l;
      }
  }
*/




type TrackProps = {};

const TrackMapEvents: React.FC<TrackProps> = () => {
  const {zoom, iconzoom, setZoom } = React.useContext(MapContext) as IMapInterface;

  const mapEvents = useMapEvents({
    zoomend: (e) => {
      const newzoom = mapEvents.getZoom();
      if (zoom != newzoom) {
        //UtilsService.consoledev('Zoom has changed', zoom, iconzoom, newzoom);
        //setTimeout(() => {setZoom(newzoom)}, 0);
      }
    }
  });

  return (
    null
  );
}


// Track the last N selected facilities
const nTrackedFacilities = 3;


/**
 * Display a facility location
 */
const Location: React.FC<LocationProps> = ({facilityid, recent, name, city, state, zip, lat, lng, cmsrating}) => {
  const {matches, recentMatches, saveSelectedFacility } = React.useContext(MatchContext) as IMatchInterface;

  const emptySettings = {
    sessionid: '',
    requestid: '',
    facilitySearch: '',
    descriptionSearch: '',
    codeSearch: '',
    facilityRetrival: ''
  }
  const [currentSettings, setCurrentSettings] = useState<FacilitySearchSettings>(emptySettings);


  const onSelectFacility = async (facilityid: string) => {
    //event.preventDefault();
    //UtilsService.consoledev('onSelectFacility', facilityid);
    // Lookup the id in matches and set the selectedfacility
    const match = matches.find((match) => match.id === facilityid);
    //UtilsService.consoledev('match', match);
    if (match) {
      UtilsService.consoledev('match', match);
      saveSelectedFacility(match);

      // Analytics
      analyticsService.pageView(`facility select: facility=${match?.name};${match?.city};${match?.state}`, window.location.href);

      // Close any open map popups
      //const map = useMap();
      //map.closePopup();

      // Also update the selected facility
      //const settings = {...currentSettings};
      //settings.facilitySearch = match.name;
      //UtilsService.consoledev('updating settings', settings);
      //setCurrentSettings(settings);
    }
  };
  const selectfacilityclick = useAsyncCallback(onSelectFacility);

  // console.log('Location', facilityid, recent);
  return (
    <label className="form_dropdown-link locations_dropdown-link w-radio">
      <input type="radio" name="Hospital-Name-City-Zip" id={facilityid} data-name="Hospital Name, City, Zip" className="w-form-formradioinput form_hidden-radio-button w-radio-input" onClick={() =>selectfacilityclick.execute(facilityid)} value={facilityid}/>
      <span className="form_dropdown-radio-button-label w-form-label">{zip}</span>
      <div className="dropdown-link_text">
      {recent &&
        <span style={{fontSize: 'smaller', color: '#0059A7'}}>(Recent)&nbsp;{name}</span>
      }
      {!recent &&
        <span>{name}</span>
      }
      </div>
      <div className="locations_dropdown-link-row"><img loading="lazy" src="images/marker-pin-01.svg" alt="" className="locations_icon"/>
        <div className="locations_text mg-right-10px">{city}</div>
        <div className="locations_text">{zip}</div>
      </div>
    </label>
  );
};

/**
 * Display all matched facility locations
 */
const Locations = () => {
  const { filteredMatches } = React.useContext(MatchContext) as IMatchInterface;

  return (
    <div>
      <>
        {filteredMatches.map((match: IFacilityInterface) => (
          <Location key={uuidv4()} facilityid={match.id} recent={match.recent} name={match.name} city={match.city} state={match.state} zip={match.zip} lat={match.lat} lng={match.long} cmsrating={match.cmsrating} />
        ))}
      </>
    </div>
  );
};



const Item2: React.FC<ItemProps> = ({itemid, description, code, code_type, item_codes}) => {
  const { itemMatches, saveSelectedItem, matchCodeSearch, saveDescriptionSearch, saveCodeSearch } = React.useContext(MatchContext) as IMatchInterface;

  const emptySettings = {
    sessionid: '',
    requestid: '',
    facilitySearch: '',
    descriptionSearch: '',
    codeSearch: '',
    facilityRetrival: ''
  }
  const [currentSettings, setCurrentSettings] = useState<FacilitySearchSettings>(emptySettings);

  //console.log('item2', item_codes, matchCodeSearch);

  const onSelectItem = async (itemid: string) => {
    //event.preventDefault();
    UtilsService.consoledev('onSelectItem', itemid);
    // Lookup the id in matches and set the selectedfacility
    const match = itemMatches.find((match) => match.item_id === itemid);
    //UtilsService.consoledev('match', match);
    if (match) {
      UtilsService.consoledev('item match', match);
      saveSelectedItem(match);

      //saveDescriptionSearch(match.item_description);
      //saveCodeSearch(match.item_code);
      //saveSelectedFacility(match);
    }
  };
  const selectitemclick = useAsyncCallback(onSelectItem);

  return (
    <label className="form_dropdown-link w-radio">
      <input type="radio" name="Item-Description" id={itemid} data-name="Item-Description" className="w-form-formradioinput form_hidden-radio-button w-radio-input" onClick={() =>selectitemclick.execute(itemid)} value={itemid}/>
      <div fs-cmsselect-element="text-value" className="dropdown-link_text item-description">{description}</div>
      <div className="form_dropdown-code-wrapper">
        <div fs-cmsselect-element="text-value" className="dropdown-link_text code item-div">{code_type}&nbsp;{code}</div>
      </div>
    </label>
  )
  /*
  return (
    <label className="form_dropdown-link w-radio" onClick={() =>selectitemclick.execute(itemid)}>
      <input type="radio" name="Item-Description" id={itemid} data-name="Item-Description" className="w-form-formradioinput form_hidden-radio-button w-radio-input" value={description}/>
      <span className="form_dropdown-radio-button-label w-form-label">{description}</span>
      <div fs-cmsselect-element="text-value" className="dropdown-link_text item-description">{description}</div>
      <div className="form_dropdown-code-wrapper">
        <div fs-cmsselect-element="text-value" className="dropdown-link_text code">{code_type}&nbsp;{code}</div>
      </div>
    </label>
  );
  */
};

/**
 * Display all Items for a facility
 */
const Items2 = () => {
  const { itemMatches, showItems, saveShowItems } = React.useContext(MatchContext) as IMatchInterface;

  const ref = UseOutsideClick(() => {
    if (showItems) {
      // console.log('Clicked outside Items2');
      saveShowItems(false);
    }
  });

  return (
    <div ref={ref} className="item_container" >
    <div className="item_first-column" style={{display: showItems ? 'block' : 'none', zIndex: 99999,
      backgroundColor: 'rgba(255,255,255,1)'
    }}>
      
      <div className="form-block-map w-form">
        {/*
        <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="form" data-wf-page-id="666f2106d12ff507b725730c" data-wf-element-id="d2457a92-7bef-3831-9fe9-14da9fa47c6f">
        */}
          <div data-hover="false" data-delay="0" className="form_dropdown_map w-dropdown">
            <nav style={{marginTop: '5px'}} className="form_dropdown-list visible w-dropdown-list">

        <>
          {itemMatches.map((match: IItemInterface) => (          <Item2 key={match.item_id} itemid={match.item_id} description={match.item_description} code={match.item_code} code_type={match.item_code_type} item_codes={match.item_codes} />
          ))}
        </>

            </nav>
          </div>
          {/*
        </form>
        */}
        <div className="hidden-message w-form-done"></div>
        <div className="hidden-message w-form-fail"></div>
      </div>
    </div>
    </div>
  );
};

/*
          <div className="map_first-column">
            <div className="form-block-map w-form">
              <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="form" data-wf-page-id="666f2106d12ff507b725730c" data-wf-element-id="d2457a92-7bef-3831-9fe9-14da9fa47c6f">
                <div data-hover="false" data-delay="0" className="form_dropdown_map w-dropdown">
                  <div className="form_dropdown-toggle city map w-dropdown-toggle"></div>
                  <nav className="form_dropdown-list visible w-dropdown-list">
                    <Locations />
                  </nav>
                </div>
              </form>
              <div className="hidden-message w-form-done"></div>
              <div className="hidden-message w-form-fail"></div>
            </div>
*/


const codeTypeInfo = `
    <div className="hover-block">
      <span className="semibold-text-span">CPT</span> codes - Current Procedural Terminology (CPT®) codes describe medical procedures. They are 5-digits and range from 00100–99499.<br/><br/>
      <span className="semibold-text-span">HCPCS</span> codes - Healthcare Common Procedure Coding System (HCPCS) codes are standardized codes for medical supplies, products and services. They are 5-digits with a letter followed by four numbers, and range from A0000–V9999. <br/><br/>
      <span className="semibold-text-span">MS-DRG</span> codes - Medicare Severity-Diagnosis Related Group (MS-DRG) codes categorize a Medicare patient&#x27;s inpatient hospital stays based on diagnosis, severity, and resource utilization. They are 3-digits. <br/><br/>
      <span className="semibold-text-span">APR-DRG</span> codes - All Patient Refined Diagnosis Related Group (APR DRG) codes classify treatments for groups of patients  according to their reason of admission, severity of illness and risk of mortality. They are 4-digits. <br/><br/>
      <span className="semibold-text-span">NDC</span> codes - National Drug Code (NDC) is a unique 10-digit or 11-digit number that identifies all prescription and nonprescription (OTC) medication, including their packages and inserts. <br/><br/>
      <span className="semibold-text-span">ICD</span> codes - International Classification of Diseases (ICD) codes are used to specify additional information about the procedures being performed for inpatient hospital stays. They are 9 or 10 digits, often in groups “5 digits-4 digits-2 digits” separated by dashes. <br/><br/>
      <span className="semibold-text-span">APC</span> - Ambulatory Payment Classification (APC) codes identify outpatient services billed to Medicare. They are 4-digits. <br/><br/>
      <span className="semibold-text-span">Revenue codes</span> - Revenue Codes exist for every item and service in a hospital’s chargemaster. They are 4-digits and are added to hospital bills to indicate the type or location of the service or item to insurers.</div>
    </div>
  `;




/**
 * Prevent charges from running multiple times
 */
let lastchargesrun = 0;

/**
 * Prevent csv from running multiple times
 */
let lastcsvrun = 0;

// Track changes to the map extends to minimize redraws
let lastcenter = {lat:0, lng:0};
let lastzoom = 0;
let lastbounds = [[0,0], [0,0]];


function FacilitySearch(props: FacilitySearchProps) {
  const emptySettings = {
    sessionid: '',
    requestid: '',
    facilitySearch: '',
    descriptionSearch: '',
    codeSearch: '',
    facilityRetrival: ''
  }
  const [currentSettings, setCurrentSettings] = useState<FacilitySearchSettings>(emptySettings);
  const [overlayactive, setOverlayActive] = useState<boolean>(false);

  const { centerlat, centerlong, latsum, zoom, iconzoom, bounds, saveMap } = React.useContext(MapContext) as IMapInterface;
  const { sessionid, facilitySearch, descriptionSearch, codeSearch, facilityid, saveSearch, saveFacilityId } = React.useContext(SearchContext) as ISearchInterface;
  const { matchFacilitySearch, matchDescriptionSearch, matchCodeSearch, facilityFilter, matchState, matches, filteredMatches, recentMatches, itemMatches, itemState, showItems, chargeMatches, chargeHeaders, chargeData, filter_setting, filter_billingclass, filter_modifiers, hasSetting, hasBillingClass, hasModifiers, hasNotes, charge_min, charge_max, charge_gross, charge_cash, chargeState, selectedFacility, selectedItem, stateview, listview, mapview, welcome, welcomeState, showgraph, saveMatches, saveChargeMatches, saveCharges, saveCsvCharges, saveFacilityMatches, saveFacilityFilter, saveItemMatches, saveView, saveSelectedFacility, clearFacility, clearItem, saveSelectedItem, saveChargeState, saveShowItems, saveItemState, saveSearchParameters, saveFacilitySearch, saveDescriptionSearch, saveCodeSearch, saveFilterSetting, saveFilterBillingClass, saveFilterModifiers, saveShowGraph } = React.useContext(MatchContext) as IMatchInterface;

  // Display the code information
  const [referenceCodeElement, setReferenceCodeElement] = useState<HTMLElement | null>(null);
  const [popperCodeElement, setPopperCodeElement] = useState<HTMLElement | null>(null);
  const [arrowCodeElement, setArrowCodeElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceCodeElement, popperCodeElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowCodeElement } }],
  });

  const [lasterror, setLastError] = useState<string>('');
  const [lastmessage, setLastMessage] = useState<string>('');



  useEffect(() => {
    UtilsService.consoledev('***** matches has changed', matches.length);
  }, [matches]);

  useEffect(() => {
    UtilsService.consoledev('matchFacilitySearch has changed', matchFacilitySearch);
    retrieveFacility(matchFacilitySearch).then();
  }, [matchFacilitySearch]);

  useEffect(() => {
    if (itemState === '' || itemState === 'pending') {
      UtilsService.consoledev('matchDescriptionSearch or matchCodeSearch has changed', matchDescriptionSearch, matchCodeSearch, itemState);
      runitemsasync().then();
    }
    // retrieveItem(matchDescriptionSearch, matchCodeSearch).then();
  }, [matchDescriptionSearch, matchCodeSearch]);

  useEffect(() => {
    UtilsService.consoledev('chargeState has changed', chargeState, Date.now());
    if (chargeState === 'pending') {
      //setTimeout(() => {
        runchargesasync().then();
      //}, 0);
    }
  }, [chargeState]);

  /**
   * Parse a string as a float, and ignore dollar sign and commas
   * @param value 
   */
  const parseFloatPermissive = (value: string): number => {
    if (!value) {
      return NaN;
    }
    return parseFloat(value.replace(/\$/g, '').replace(/\,/g, ''));
  }

  /**
   * Build the data for generating a csv file
   */
  const computeChargeStats = (matches: IChargeInterface[]) => {
    let chargemin = '';
    let chargemax = '';
    let chargegross = '';
    let chargecash = '';

    let hasSettingValue = false;
    let hasBillingClassValue = false;
    let hasModifiersValue = false;
    let hasNotesValue = false;

    // Find the min, max, average, cash charge
    let mins = [];
    let maxes = [];
    let grosses = [];
    let cashes = [];
    for (const charge of matches) {
      const fmin = parseFloatPermissive(charge.charge_min);
      const fmax = parseFloatPermissive(charge.charge_max);
      const fgross = parseFloatPermissive(charge.charge_gross);
      const fcash = parseFloatPermissive(charge.charge_cash);
      if (charge?.charge_min && fmin > 0) {
        mins.push(fmin);
      }
      if (charge?.charge_max && fmax > 0) {
        maxes.push(fmax);
      }
      if (charge?.charge_gross && fgross > 0) {
        grosses.push(fgross);
      }
      if (charge?.charge_cash && fcash > 0) {
        cashes.push(fcash);
      }

      if (charge.charge_setting && charge.charge_setting.length > 0) {
        hasSettingValue = true;
      }
      if (charge.charge_billing_class && charge.charge_billing_class.length > 0) {
        hasBillingClassValue = true;
      }
      if (charge.item_modifier && charge.item_modifier.length > 0) {
        hasModifiersValue = true;
      }

      // Code from Charges.tsx to determine if there are notes
      const estimated_amount_formatted = UtilsService.formatCurrency(charge.ppc_estimated_amount);

      const infofields: any = {
        //'Setting': charge.charge_setting,
        //'Billing Class': charge.charge_billing_class,
        'Estimated Amount' : estimated_amount_formatted,
        'Negotiated Algorithm' : charge.ppc_negotiated_algorithm,
        'Negotiated Methodology' : charge.ppc_negotiated_methodology,
        'Negotiated Percentage' : charge.ppc_negotiated_percentage,
        'Generic Notes' : charge.item_generic_notes,
        'Extra Fields' : charge.item_extra_fields,
        'Additional Payer Notes' : charge.ppc_additional_payer_notes
      }
      let fields : string[] = Object.values(infofields);
      fields = fields.filter(x => x && x.trim().length > 0);
    
      let hasinfo = fields.some(x => x?.length>0);
      if (hasinfo) {
        hasNotesValue = true;
      }

    }

    chargemin = (mins.length > 0) ? '$' + Math.min(...mins).toFixed(2).toString() : '';
    chargemax = (maxes.length > 0) ? '$' + Math.max(...maxes).toFixed(2).toString() : '';
    chargegross = (grosses.length > 0) ? '$' + (grosses.reduce((a, b) => a + b) / grosses.length).toFixed(2).toString() : '';
    chargecash = (cashes.length > 0) ? '$' + (cashes.reduce((a, b) => a + b) / cashes.length).toFixed(2).toString() : '';

    //UtilsService.consoledev('mins', mins);
    //UtilsService.consoledev('maxes', maxes);
    //UtilsService.consoledev('grosses', grosses);
    //UtilsService.consoledev('cashes', cashes);
    return [chargemin, chargemax, chargegross, chargecash, hasSettingValue, hasBillingClassValue, hasModifiersValue, hasNotesValue];
  }

/*
  useEffect(() => {
    UtilsService.consoledev('chargeMatches has changed', chargeMatches.length);
    //console.log('chargeMatches', chargeMatches);

    computeChargeStats(chargeMatches);
  }, [chargeMatches]);
*/

  useEffect(() => {
    UtilsService.consoledev('charges have changed', charge_min, charge_max, charge_gross, charge_cash);
  }, [charge_min, charge_max, charge_gross, charge_cash]);


  useEffect(() => {
    UtilsService.consoledev('FacilitySearch useEffect', currentSettings);
    // UtilsService.consoledev('FacilitySearch. New search to run', search, searchState);

    // Make the search sticky by retrieving the search value
    if (currentSettings.facilitySearch != facilitySearch || currentSettings.descriptionSearch != descriptionSearch || currentSettings.codeSearch != codeSearch) {
      const settings = {...currentSettings};
      if (settings.facilitySearch != facilitySearch) {
        settings.facilitySearch = facilitySearch;
      }
      if (settings.descriptionSearch != descriptionSearch) {
        settings.descriptionSearch = descriptionSearch;
      }
      if (settings.codeSearch != codeSearch) {
        settings.codeSearch = codeSearch;
      }
      setCurrentSettings(settings);
    }

    // Record analytics
    if ((facilitySearch && facilitySearch.length > 0) || (descriptionSearch && descriptionSearch.length > 0) || (codeSearch && codeSearch.length > 0)) {
      //analyticsService.pageView(`search ${facilitySearch},${descriptionSearch},${codeSearch}`, window.location.href);
      //analyticsService.pageTracking('search', 'search', {search: searchString});
      // UtilsService.consoledev('Search', searchString);

      retrieve(facilitySearch, descriptionSearch, codeSearch).then();
    }

    // UtilsService.consoledev('Mapview useEffect', matches.length);
  }, [facilitySearch, descriptionSearch, codeSearch]);

  // Effect that runs when matches or settings change
  useEffect(() => {
    // UtilsService.consoledev('Mapview useEffect. matches has changed', matches.length);

    const matchesWithCoords: IFacilityInterface[] = (selectedFacility && selectedFacility.id) ? [selectedFacility] : filteredMatches.filter(x => x.lat && x.lat.length > 0 && x.long && x.long.length > 0);

    //if (matchesWithCoords.length == 0 && mapSettings.matchesWithCoords.length != 0) {
    //  setMapSettings(emptySettings);
    //  return;
    //}

    let lat = defaultlat;
    let lng = defaultlong;

    // UtilsService.consoledev('with coords', matchesWithCoords.length)
    let latmin: number = 0.;
    let latmax: number = 0.;
    let lngmin: number = 0.;
    let lngmax: number = 0.;
  
    let latsummed = 0;
    let lngsummed = 0;
    let npoints = 0;
    for (const facility of matchesWithCoords) {
      if (facility.lat && facility.long) {
        const latvalue = parseFloat(facility.lat);
        const lngvalue = parseFloat(facility.long);
  
        latsummed += latvalue;
        lngsummed += lngvalue;
        npoints++;
  
        if (latmin == 0. || latvalue < latmin) { latmin = latvalue;}
        if (latmax == 0. || latvalue > latmax) { latmax = latvalue;}
        if (lngmin == 0. || lngvalue < lngmin) { lngmin = lngvalue;}
        if (lngmax == 0. || lngvalue > lngmax) { lngmax = lngvalue;}
      }
    }

    // UtilsService.consoledev('bounds', bounds);
    let usezoom = zoom;
    if (npoints > 0) {
      lat = latsummed / npoints;
      lng = lngsummed / npoints;

      UtilsService.consoledev('center', lat, lng, process.env.NODE_ENV);
    }
    else {
      // Always show CO
      latmin = lat - 1;
      latmax = lat + 1;
      lngmin = lng - 1;
      lngmax = lng + 1;

      usezoom = defaultzoom;
    }

    const newbounds = [[latmin, lngmax] as any, [latmax, lngmin] as any];


    //UtilsService.consoledev(`matchesWithCoords=${matchesWithCoords.length}. latmin=${latmin}, latmax=${latmax}, lngmin=${lngmin}, lngmax=${lngmax}, bounds=${bounds}`);

    UtilsService.consoledev('***** latsummed, latsum', latsummed, latsum, usezoom, bounds, newbounds);
    if (latsummed != latsum) {
      UtilsService.consoledev('map change', lat, lng, usezoom, latsummed, newbounds);
      //const settings = {...mapSettings};
      //settings.matchesWithCoords = matchesWithCoords;
      //setMapSettings(settings);
      saveMap(lat, lng, usezoom, latsummed, newbounds);
    }

  }, [filteredMatches, selectedFacility]);

  // Run once matchState is available
  /*
  useEffect(() => {
    UtilsService.consoledev('matchState is ', matchState);
    if (!retrieveAllFacilitiesLock && !matchState) {
      retrieveAllFacilitiesLock = true;
      UtilsService.consoledev('useEffect after initial render');

      retrieveAllFacilities().then();
    }
  }, [matchState]);
  */

  // Retrieve items once a facility is selected

  useEffect(() => {
    UtilsService.consoledev('selectedFacility is ', selectedFacility);

    setLastError('');
    //TODO Don't retrieve data if we already have it

    // Retrieve all items for this facility
    //if (selectedFacility) {
    //  retrieveAllItems().then();
    //}


  }, [selectedFacility]);

  useEffect(() => {
    UtilsService.consoledev('selectedItem is ', selectedItem);
    //console.log('centerlat', centerlat);
    //console.log('centerlong', centerlong);
    //console.log('latsum', latsum);
    //console.log('zoom', zoom);
    //console.log('iconzoom', iconzoom);
    //console.log('bounds', bounds);


    const settings = {...currentSettings};
    settings.descriptionSearch = selectedItem ? selectedItem.item_description : '';
    settings.codeSearch = selectedItem ? selectedItem.item_code : '';
    setCurrentSettings(settings);

    UtilsService.consoledev('Updated search settings', settings);


    //TODO Don't retrieve data if we already have it

    // Retrieve all items for this facility
    //if (selectedFacility) {
    //  retrieveAllItems().then();
    //}


  }, [selectedItem]);

  // Run once after the initial render
  useEffect(() => {
    if (!retrieveAllFacilitiesLock && matchState && matchState.length > 0) {
      retrieveAllFacilitiesLock = true;
      UtilsService.consoledev('useEffect after initial render');

      retrieveAllFacilities().then();
    }
  }, []);

  useEffect(() => {
    UtilsService.consoledev('*****chargeHeaders has changed');
    //console.log('*****chargeHeaders', chargeHeaders);
    //console.log('*****chargeData', chargeData);

  }, [chargeData]);


  /**
   * Build the data for generating a csv file
   */
  const buildCsv = (matches: IChargeInterface[], facility: IFacilityInterface | undefined, item: IItemInterface | undefined, chargemin: string, chargemax: string, chargegross: string, chargecash: string) => {
    const headers = [];
    const datarows = [];

    // Find the column names by checking the info
    const cols = ['payer', 'plan', 'setting', 'billing class', 'modifier', 'negotiated charge', 'estimated amount', 'negotiated methodology', 'negotiated percentage', 'negotiated algorithm'];
    const icols = ['ppc_payer', 'ppc_plan', 'charge_setting', 'charge_billing_class', 'item_modifier', 'ppc_negotiated_charge', 'ppc_estimated_amount', 'ppc_negotiated_methodology', 'ppc_negotiated_percentage', 'ppc_negotiated_algorithm'];

    let datarow: any = {};

    // Find additional columns to display (from item_extra_fields and item_generic_notes)
    const cols_extra: string[] = [];
    for (const row of matches) {
      // Examine item_extra_fields
      if (row.item_extra_fields) {
        try {
          const extra = JSON.parse(row.item_extra_fields);
          for (const key of Object.keys(extra)) {
            const value = extra[key];
            if (value && value.length > 0 && !cols_extra.includes(key)) {
              cols_extra.push(key);
            }
          }
        }
        catch {
          // Ignore parsing errors
        }
      }

      // Examine item_generic_notes
      if (row.item_generic_notes) {
        try {
          const extra = JSON.parse(row.item_generic_notes);
          for (const key of Object.keys(extra)) {
            const value = extra[key];
            if (value && value.length > 0 && !cols_extra.includes(key)) {
              cols_extra.push(key);
            }
          }
        }
        catch {
          // Ignore parsing errors
        }

      }
    }

    // Sort so the csv report will be consistent
    cols_extra.sort();

    const data_extra: any[] = [];
    if (cols_extra.length > 0) {
      for (const row of matches) {
        // Start with an array of blanks for values
        const edata: any = {};
        for (const key of cols_extra) {
          edata[key] = '';
        }

        let extra: any = {};
        // Examine item_extra_fields
        if (row.item_extra_fields) {
          try {
            const extra = JSON.parse(row.item_extra_fields);
            for (const key of Object.keys(extra)) {
              const value = extra[key];
              if (value && value.length > 0) {
                edata[key] = value;
              }
            }
          }
          catch {
            // Ignore parsing errors
          }
        }

        // Examine item_generic_notes
        if (row.item_generic_notes) {
          try {
            const extra = JSON.parse(row.item_generic_notes);
            for (const key of Object.keys(extra)) {
              const value = extra[key];
              if (value && value.length > 0) {
                edata[key] = value;
              }
            }
          }
          catch {
            // Ignore parsing errors
          }
        }

        data_extra.push(edata);
      }
    }

    //console.log('data_extra', data_extra);


    // Find all codes from item_codes to display. It must have a code and code type
    const cols_code: string[] = [];
    for (const row of matches) {
      if (row.item_codes) {
        try {
          const codes = JSON.parse(row.item_codes);
          for (const code of codes) {
            const ct = code[0] as string;
            const c = code[1];
            if (c && ct) {
              if (!cols_code.includes(ct)) {
                cols_code.push(ct);
              }
            }
          }
        }
        catch {
          // Ignore parsing errors
        }
      }
    }
    // console.log('cols_code=',cols_code);

    const ncols = cols.length + cols_code.length + cols_extra.length;

    // Table rows
    let rowindex = 0;
    for (const row of matches) {
      datarow = {};
      for (let i=0; i<cols.length; i++) {
        let value: string = (row as any)[icols[i]] || '';

        // Format the negotiated_charge. Don't display if this is an estimated_amount
        if (cols[i] === 'negotiated charge') {
          const est = row.ppc_estimated_amount;
          console.log('est', est);
          if (est && !est.startsWith('999999999')) {
            value = '';
          }
          else {
            value = row.ppc_negotiated_charge_formatted;
          }
        }
        
        if (icols[i] === 'ppc_estimated_amount' && !value.startsWith('999999999')) {
          value = UtilsService.formatCurrency(value);
        }
        if (cols[i] === 'setting') {
          switch (value) {
            case 'ip':
              value = 'inpatient';
              break;
            case 'op':
              value = 'outpatient';
              break;
            default:
              break;
          }
        }

        datarow[cols[i]] = value;
      }

      // Add the codes
      const codelookup: any = {};
      try {
        const codes = JSON.parse(row.item_codes);
        for (const code of codes) {
          const ct = code[0] as string;
          const c = code[1];
          if (c && ct) {
            codelookup[ct] = c;
          }
        }
      }
      catch {
        // Ignore parsing errors
      }

      for (const ccode of cols_code) {
        let value = (codelookup as any)[ccode] || '';
        datarow[ccode] = value;
      }


      // Add additional fields
      if (cols_extra.length > 0) {
        const dextra = data_extra[rowindex];
        for (const cextra of cols_extra) {
          let value = dextra[cextra] || '';
          datarow[cextra] = value;
        }
      }

      // console.log('datarow', datarow);
      datarows.push(datarow);
      rowindex++;
    }

    for (const col of cols) {
      headers.push({label: '', key: col});
    }
    for (const ccode of cols_code) {
      headers.push({label: '', key: ccode});
    }
    for (const ecode of cols_extra) {
      headers.push({label: '', key: ecode});
    }
    // console.log('headers', headers);
  
    // Helper method to generate a blank row which you can modify
    const blankrow = (): any => {
      const datarow: any = {};
      for (const col of cols) {
        datarow[col] = '';
      }
      for (const ccode of cols_code) {
        datarow[ccode] = '';
      }
      for (const ecode of cols_extra) {
        datarow[ecode] = '';
      }
      return datarow;
    }

    // Sort datarows by payer
    datarows.sort((a, b) => {
      return a.payer.localeCompare(b.payer);
    });

    // Add the header line
    datarow = {};
    for (const col of cols) {
      datarow[col] = col;
    }
    for (const ccode of cols_code) {
      datarow[ccode] = ccode
    }
    for (const ecode of cols_extra) {
      datarow[ecode] = ecode;
    }
    datarows.unshift(datarow);

    // Add blank line
    datarow = blankrow();
    datarows.unshift(datarow);

    // Add blank line
    datarow = blankrow();
    datarows.unshift(datarow);

    // Disclaimer
    datarow = blankrow();
    const disclaimer = `This spreadsheet was created using PRA’s ${region_name} Hospital Price Finder, which relies on price data obtained from hospital publications. Because of the scope and constantly changing nature of hospital prices along with inherent variability in interpreting medical coding systems and normalizing pricing information, PRA makes no warranty, and expressly disclaims all warranties, as to the accuracy or completeness of the information provided herein. Price and other information should always be verified directly with hospitals and service providers. All use of this spreadsheet and PRA’s ${region_name} Hospital Price Finder is governed by our Terms of Service [https://www.patientrightsadvocate.org/termsofservice], to which you have expressly agreed. CPT® copyright 2020 American Medical Association. All Rights Reserved.`;
    datarow['payer'] = disclaimer;
    datarows.unshift(datarow);

    // Add blank line
    datarow = blankrow();
    datarows.unshift(datarow);

    // Show gross, cash, min, max
    datarow = blankrow();
    datarow['payer'] = `Gross Charge: ${chargegross ? chargegross : 'N/A'}`;
    datarow['plan'] = `Discounted Cash: ${chargecash? chargecash : 'N/A'}`;
    datarow['setting'] = `Min: ${chargemin ? chargemin : 'N/A'}`;
    datarow['billing class'] = `Max: ${chargemax ? chargemax : 'N/A'}`;
    datarows.unshift(datarow);    

    // Add the search information
    if (item) {
      datarow = blankrow();
      datarow['payer'] = `Description: ${item.item_description}`;
      datarow['plan'] = item.item_code_type ? `Code: ${item.item_code_type} ${item.item_code}` : `Code: ${item.item_code}`;
      datarows.unshift(datarow);
    }

    // Add the hospital information. This will be the headers

    if (facility) {
      datarow = blankrow();
      datarow['payer'] = `${facility.name} ${facility.city}, ${facility.state} ${facility.zip}`;
      datarow['plan'] = facility.pageurl;
      datarow['setting'] = `Last Updated: ${facility.lastupdated}`;
      datarow['billing class'] = `Generated: ${new Date().toISOString().replace('T', ' ')}`;
      //datarows.unshift(datarow);

      headers.length = 0;
      for (const col of cols) {
        headers.push({label: datarow[col], key: col});
      }
      for (const ccode of cols_code) {
        headers.push({label: datarow[ccode], key: ccode});
      }
      for (const ecode of cols_extra) {
        headers.push({label: datarow[ecode], key: ecode});
      }
    }

    // console.log('FacilitySearch buildCsv', headers, datarows);
    return [JSON.stringify(headers), JSON.stringify(datarows)];
  }

  //let hascharges = chargeMatches.length > 0;

  // Re-build the CSV data
  React.useEffect(() => {
    const now = Date.now();
    const delta = now - lastcsvrun;
    lastcsvrun = now;

    // hascharges = chargeMatches.length > 0;
    if (delta < 1000) {
      UtilsService.consoledev('Too close to run csv again', delta);
      return;
    }

    let [chargemin, chargemax, chargegross, chargecash, hasSettingValue, hasBillingClassValue, hasModifiersValue, hasNotesValue] = computeChargeStats(chargeMatches);
    chargemin = UtilsService.formatCurrency(chargemin as string);
    chargemax = UtilsService.formatCurrency(chargemax as string);
    chargegross = UtilsService.formatCurrency(chargegross as string);
    chargecash = UtilsService.formatCurrency(chargecash as string);

    UtilsService.consoledev('*****Rebuilding csv', chargeMatches.length);
    const [headers, datarows] = buildCsv(chargeMatches, selectedFacility, selectedItem, chargemin, chargemax, chargegross, chargecash);

    UtilsService.consoledev(`Recomputed min=${chargemin}, max=${chargemax}, gross=${chargegross}, cash=${chargecash}`);
    saveCsvCharges(headers, datarows, chargemin, chargemax, chargegross, chargecash, hasSettingValue as boolean, hasBillingClassValue as boolean, hasModifiersValue as boolean, hasNotesValue as boolean);
  }, [chargeMatches]);


  const onSelectFacility = (facilityid: string) => {
    // console.log('onSelectFacility', facilityid);
    //event.preventDefault();
    const match = matches.find((match) => match.id === facilityid);
    if (match) {
      UtilsService.consoledev('match', match);
      saveSelectedFacility(match);
    }
  };
  const selectfacilityclick = useAsyncCallback(onSelectFacility);


  const states = [
    ['All','State'],
    ['Alabama','AL'],
    ['Alaska','AK'],
    ['Arizona','AZ'],
    ['Arkansas','AR'],
    ['American Samoa','AS'],
    ['California','CA'],
    ['Colorado','CO'],
    ['Connecticut','CT'],
    ['Delaware','DE'],
    ['District of Columbia','DC'],
    ['Florida','FL'],
    ['Georgia','GA'],
    ['Guam','GU'],
    ['Hawaii','HI'],
    ['Idaho','ID'],
    ['Illinois','IL'],
    ['Indiana','IN'],
    ['Iowa','IA'],
    ['Kansas','KS'],
    ['Kentucky','KY'],
    ['Louisiana','LA'],
    ['Maine','ME'],
    ['Maryland','MD'],
    ['Massachusetts','MA'],
    ['Michigan','MI'],
    ['Minnesota','MN'],
    ['Mississippi','MS'],
    ['Missouri','MO'],
    ['Montana','MT'],
    ['Nebraska','NE'],
    ['Nevada','NV'],
    ['New Hampshire','NH'],
    ['New Jersey','NJ'],
    ['New Mexico','NM'],
    ['New York','NY'],
    ['North Carolina','NC'],
    ['North Dakota','ND'],
    ['Northern Mariana Islands','MP'],
    ['Ohio','OH'],
    ['Oklahoma','OK'],
    ['Oregon','OR'],
    ['Pennsylvania','PA'],
    ['Puerto Rico','PR'],
    ['Rhode Island','RI'],
    ['South Carolina','SC'],
    ['South Dakota','SD'],
    ['Tennessee','TN'],
    ['Texas','TX'],
    ['Utah','UT'],
    ['Vermont','VT'],
    ['Virginia','VA'],
    ['Virgin Islands','VI'],
    ['Washington','WA'],
    ['West Virginia','WV'],
    ['Wisconsin','WI'],
    ['Wyoming','WY']
  ];

  // Sort state by abbreviation, except for the first entry
  const sortedstates = states.slice(1);
  sortedstates.sort((a,b) => a[1].localeCompare(b[1]));
  sortedstates.unshift(states[0]);


  function clearSettings()
  {
    setCurrentSettings(emptySettings);
  }

  function setFacilitySearch(newfacilitysearch: string)
  {
    // UtilsService.consoledev('setFacilitySearch', newfacilitysearch, searchState);

    //const settings = {...currentSettings};
    //settings.facilitySearch = newfacilitysearch;
    //setCurrentSettings(settings);

    // Clear the current facility
    //saveFacilitySearch(newfacilitysearch);
    saveFacilityFilter(newfacilitysearch);
    setLastError('');
    //saveSelectedFacility(undefined);
  }

  function setDescriptionSearch(newdescriptionsearch: string)
  {
    const settings = {...currentSettings};
    settings.descriptionSearch = newdescriptionsearch;
    setCurrentSettings(settings);

    //saveDescriptionSearch(newdescriptionsearch);
  
  }

  function setCodeSearch(newcodesearch: string)
  {
    const settings = {...currentSettings};
    settings.codeSearch = newcodesearch;
    setCurrentSettings(settings);

    //saveCodeSearch(newcodesearch);
  }


  function setListview(state: boolean)
  {
    saveView(stateview, state, !state, false);
  }

  function setMapview(state: boolean)
  {
    // UtilsService.consoledev('setMapview', state);
    saveView(stateview, !state, state, false);
  }

  function setError(error: string)
  {
    const settings = {...currentSettings};
    settings.error = error;
    setCurrentSettings(settings);
  }

  // Retrieve a list of all facilities and do the filtering in the client
  let retrieveAllFacilitiesLock = false;
  const retrieveAllFacilities = async () => {
    try {
      UtilsService.consoledev('Calling facility/all', retrieveAllFacilitiesLock, matchState);
      const data = await serverService.getResponse(`facility/all?state=${matchState}`);
      //UtilsService.consoledev('data', data);
      if (!('matches' in data)) {
        data['matches'] = [];
      }

      //UtilsService.consoledev('Raw Matches', data['matches'])
  
      // Camel-case the name, city names
      for (const facility of data['matches']) {
        facility.city = facility.city.toLowerCase().replace(/\b\w/g, (l: any) => l.toUpperCase());
        facility.address = facility.address.toLowerCase().replace(/\b\w/g, (l: any) => l.toUpperCase());

        // Only adjust case for name if not mixed case
        if (facility.name === facility.name.toUpperCase()) {
          facility.name = facility.name.toLowerCase().replace(/\b\w/g, (l: any) => l.toUpperCase());
        }
        // UtilsService.consoledev(facility.city)
      }

      // The same facility might be found more than once (it's in the parquet data)
      const unique: any = {};
      for (const facility of data['matches']) {
        if (facility.id in unique) {
          // Ignore the duplicate
        }
        else {
          unique[facility.id] = facility;
        }
      }
      data['matches'] = Object.values(unique);

      // Sort by name
      data['matches'].sort((a: any, b: any) => a.name.localeCompare(b.name));

      //UtilsService.consoledev(data['matches']);

      saveFacilityMatches(data['matches'], data['error']);
  
      // Update the state
      const settings = {...currentSettings};
      settings.facilityRetrival = 'fetched';
      setCurrentSettings(settings); 
    }
    finally {
      // retrieveAllFacilitiesLock = false;
    }

  }  
  
  
  // Allow the Editor to call the grid for current grid values
  //const gridRef = useRef<GridObject>(null);
  //const tableformRef = useRef<TableFormObject>(null);

  const retrieveFacility = async (newfacilitysearch: string) => {
    //if (matchFacilitySearch === newfacilitysearch) {
     // return;
    //}
    if (matchFacilitySearch.length < 3) {
      // Name is too short
      return;
    }

    UtilsService.consoledev('Calling facility/search');
    const data = await serverService.getResponse(`facility/search?search=${matchFacilitySearch}`);
    //UtilsService.consoledev('data', data);
    if (!('matches' in data)) {
      data['matches'] = [];
    }

    saveFacilityMatches(data['matches'], data['error']);
    //saveMatches(newfacilitysearch, descriptionSearch, codeSearch, data['matches'], [], [], data['error']);

    // facilityid is set only if there is one result
    //let facilityid = data['matches'].length === 1 ? data['matches'][0].id : '';
    //saveFacilityId(facilityid);
    //saveMatches(newfacilitysearch, newdescriptionsearch, newcodesearch, data['matches'], [], [], data['error']); 
  
  }

  const retrieveItem = async (newdescriptionsearch: string, newcodesearch: string) => {
    //UtilsService.consoledev('retrieveItem', matchDescriptionSearch, newdescriptionsearch, matchCodeSearch, newcodesearch, matches.length, itemMatches.length, selectedFacility);
    //if (matchDescriptionSearch === newdescriptionsearch && matchCodeSearch === newcodesearch) {
    //  UtilsService.consoledev('UNCHANGED');
    //  //return;
    //}
    if (matchDescriptionSearch.length < 3 && matchCodeSearch.length < 3) {
      UtilsService.consoledev('TOO SHORT');
      // Name is too short
      return;
    }

    if (selectedFacility === undefined) {
      // A facility is required
      return;
    }

    const facilityid = selectedFacility?.id;

    UtilsService.consoledev('calling charge/itemsearch');
    const data = await serverService.getResponse(`charge/itemsearch?fid=${facilityid}&search=${matchDescriptionSearch}&codesearch=${matchCodeSearch}`);
    //UtilsService.consoledev('data', data);
    if (!('matches' in data)) {
      data['matches'] = [];
    }

    UtilsService.consoledev('results', data['matches']?.length, 'errors', data['error']);
    saveItemMatches(data['matches'], data['error']);

    // Give feedback to the user
    if (data['error'] && data['error'].length > 0) {
      const err = data['error'];
      if (err.includes('resultsexceeded')) {
        setLastError('Too many results, try narrowing your search');
      }
      else {
        setLastError(err);
      }
    }
    else if (!data['matches'] || data['matches'].length === 0) {
      setLastError('Item not found in the price transparency file');
    }
    else {
      setLastError('');
    }

    //saveMatches(facilitySearch, newdescriptionsearch, newcodesearch, matches, data['matches'], [], data['error']);
  }

  const retrieveAllItems = async () => {
    const facilityid = selectedFacility?.id;

    UtilsService.consoledev('calling charge/items');
    const data = await serverService.getResponse(`charge/items?fid=${facilityid}`);
    //UtilsService.consoledev('data', data);
    if (!('matches' in data)) {
      data['matches'] = [];
    }
    saveItemMatches(data['matches'], data['error']);
    //saveMatches(facilitySearch, newdescriptionsearch, newcodesearch, matches, data['matches'], [], data['error']);
  }


  const retrieveCharge = async () => {
    if (chargeState != 'pending' || !selectedItem) {
      return;
    }

    const facilityid = selectedFacility?.id;

    UtilsService.consoledev('calling charge/itemsearch');
    const data = await serverService.getResponse(`charge/itemsearch?fid=${facilityid}&search=${matchDescriptionSearch}&codesearch=${matchCodeSearch}`);
    //UtilsService.consoledev('data', data);
    if (!('matches' in data)) {
      data['matches'] = [];
    }
    UtilsService.consoledev('results', data['matches']?.length, 'errors', data['error']);
    saveItemMatches(data['matches'], data['error']);

    // Give feedback to the user
    if (data['error'] && data['error'].length > 0) {
      const err = data['error'];
      if (err.includes('resultsexceeded')) {
        setLastError('Too many results, try narrowing your search');
      }
      else {
        setLastError(err);
      }
    }
    else if (!data['matches'] || data['matches'].length === 0) {
      setLastError('Item not found in the price transparency file');
    }
    else {
      setLastError('');
    }

    //saveMatches(facilitySearch, newdescriptionsearch, newcodesearch, matches, data['matches'], [], data['error']);
  }

  // Call when ready to retrieve results
  const retrieve = async (newfacilitysearch: string, newdescriptionsearch: string, newcodesearch: string) => {
    return;

    // Don't retrieve results if they are already here
    if (matchFacilitySearch === newfacilitysearch &&
      matchDescriptionSearch === newdescriptionsearch && 
      matchCodeSearch === newcodesearch &&
      matches.length > 0) {
      UtilsService.consoledev('Already have', matchDescriptionSearch, matchCodeSearch);
      return;
    }

    // Retrieve facility matches, and clear out any existing charge information
    //let facilityid = '';
    UtilsService.consoledev('facilityid', facilityid);
    UtilsService.consoledev('retrieve', facilityid, newdescriptionsearch, newcodesearch);
    if (matchFacilitySearch !== newfacilitysearch && newfacilitysearch.length >= 3) { 
      UtilsService.consoledev('Calling facility/search');
      const data = await serverService.getResponse(`facility/search?search=${newfacilitysearch}`);
      UtilsService.consoledev('data', data);
      if (!('matches' in data)) {
        data['matches'] = [];
      }
      // facilityid is set only if there is one result
      let facilityid = data['matches'].length === 1 ? data['matches'][0].id : '';
      saveFacilityId(facilityid);
      saveMatches(newfacilitysearch, newdescriptionsearch, newcodesearch, data['matches'], [], [], data['error']); 
    }
    else if (facilityid.length > 0 && (newdescriptionsearch.length >= 3 || newcodesearch.length >= 3)) {
      UtilsService.consoledev('Calling charge/itemsearch');
      // The facility hasn't changed, so check the description and code
      const data = await serverService.getResponse(`charge/itemsearch?fid=${facilityid}&search=${newdescriptionsearch}&codesearch=${newcodesearch}`);
      UtilsService.consoledev('data', data);
      if (!('matches' in data)) {
        data['matches'] = [];
      }
      saveMatches(newfacilitysearch, newdescriptionsearch, newcodesearch, matches, data['matches'], [], data['error']);

      UtilsService.consoledev('itemMatches length is', data['matches'].length);

      //HACK Run charge search immediately if there is one result
      if (data['matches'].length === 1) {
        const item_id = data['matches'][0].item_id;
        const data2 = await serverService.getResponse(`charge/find?fid=${facilityid}&iid=${item_id}`);
        UtilsService.consoledev('data2', data2);
        if (!('matches' in data2)) {
          data2['matches'] = [];
        }
        saveMatches(newfacilitysearch, newdescriptionsearch, newcodesearch, matches, data['matches'], data2['matches'], data2['error']);

      }
    }
    else if (facilityid.length > 0 && itemMatches.length === 1) {
      // Retrieve the charges
      const item_id = itemMatches[0].item_id;
      const data = await serverService.getResponse(`charge/find?fid=${facilityid}&iid=${item_id}`);
      UtilsService.consoledev('data', data);
      if (!('matches' in data)) {
        data['matches'] = [];
      }
      saveMatches(newfacilitysearch, newdescriptionsearch, newcodesearch, matches, itemMatches, data['matches'], data['error']);

    }

    // UtilsService.consoledev('retrieve', newsearch, newsearchState, 'existing', matchSearch, matchState);

  }

  /**
   * Get the charges and save the results. Debounce the call to prevent multiple calls
   */
  const getcharges = async () => {
    const facilityid = selectedFacility?.id;
    const item_id = selectedItem?.item_id;

    UtilsService.consoledev('*****Fetching charges', facilityid, item_id);
    try {
      const data = await serverService.getResponse(`charge/find?fid=${facilityid}&iid=${item_id}`);
      UtilsService.consoledev('charge data', data['matches']);
      if (!('matches' in data)) {
        data['matches'] = [];
      }

      // Analytics
      analyticsService.pageView(`charge search: facility=${selectedFacility?.name};${selectedFacility?.city};${selectedFacility?.state},item=${selectedItem?.item_description};${selectedItem?.item_code_type};${selectedItem?.item_code},raw matches=${data['matches']?.length}`, window.location.href);


      // Add charge_id if missing. 
      for (let i = 0; i < data['matches'].length; i++) {
        if (!data['matches'][i].charge_id) {
          data['matches'][i].charge_id = uuidv4();
        } 
      }

      // Patch the charge to handle 999999999
      for (let i = 0; i < data['matches'].length; i++) {
        if (data['matches'][i].ppc_negotiated_charge) {
          let nc = data['matches'][i].ppc_negotiated_charge?.trim();
          if (nc?.startsWith('999999999')) {
            //console.log('***** patching charge');
            nc = 'not sufficient historic claims history';
            data['matches'][i].ppc_negotiated_charge = nc;
            data['matches'][i].ppc_negotiated_charge_formatted = nc;
          }
        } 

        let ec = data['matches'][i].ppc_estimated_amount;
        let ecinsufficient = false;
        if (ec?.startsWith('999999999')) {
          ec = 'not sufficient historic claims history';
          ecinsufficient = true;
        }

        // If there is no ppc_negotiated_charge, and there is an estimated_amount
        data['matches'][i].ppc_estimated = false;
        if (!data['matches'][i].ppc_negotiated_charge && data['matches'][i].ppc_estimated_amount) {
          data['matches'][i].ppc_estimated = true;
          data['matches'][i].ppc_negotiated_charge = ec;
          data['matches'][i].ppc_negotiated_charge_formatted = ecinsufficient ? ec : `${UtilsService.formatCurrency(data['matches'][i].ppc_estimated_amount)}`;
        } 
      }

      // Remove null or empty payer plan names if there are other rows with payers
      const haspayers = data['matches'].filter((x: any) => x.ppc_payer);
      if (haspayers.length > 0) {
        data['matches'] = data['matches'].filter((x: any) => x.ppc_payer || x.ppc_plan);
      }
  
      // Remove duplicate payer plan for the same setting, billing class, charge
      // TODO REMOVE ONCE FIXED IN DATA
      const uniquecharges: any = {};
      for (let i = 0; i < data['matches'].length; i++) {
        const d = data['matches'][i];
        const key = `${d.ppc_payer}-${d.ppc_plan}-${d.charge_setting}-${d.charge_billing_class}-${d.item_modiier}-${d.ppc_negotiated_charge}`;
        if (!uniquecharges[key]) {
          uniquecharges[key] = d;
        }
      }
      data['matches'] = Object.values(uniquecharges);

      // Figure out what color to display for this charge. Leave blank to use the default
      // Determine the color coding of charges. If unknown, no color is used
      let charges: number[] = [];

      for (const row of data['matches']) {
        // Only retain numerical values (remove $ and ,). Keep the % sign
        const nc = row?.ppc_negotiated_charge?.replace('$', '')?.replace(/\,/g, '');
        const c = parseFloat(nc);
        if (!isNaN(c) && c > 0) {
          charges.push(c);  
        }
      }
      charges.sort((a, b) => a - b);
      const mincharge = Math.min(...charges);
      const maxcharge = Math.max(...charges);
      //console.log('charges', charges, mincharge, maxcharge);

      let mingreen = 0, maxgreen = 0, minyellow = 0, maxyellow = 0, minred = 0, maxred = 0;
      if (mincharge == maxcharge && mincharge > 0) {
        // All values are the same
        mingreen = mincharge;
        maxgreen = maxcharge;
      }
      else if (mincharge > 0 && maxcharge > 0) {
        mingreen = mincharge;
        maxgreen = mincharge + (maxcharge - mincharge) * 0.25;
        minyellow = maxgreen;
        maxyellow = mincharge + (maxcharge - mincharge) * 0.75;
        minred = maxyellow;
        maxred = maxcharge; 
      }

      // Add color information, and a formatted version of the charge
      for (const row of data['matches']) {
        row.ppc_negotiated_charge_formatted = UtilsService.formatCurrency(row.ppc_negotiated_charge);
        const nc = row?.ppc_negotiated_charge?.replace('$', '')?.replace(/\,/g, '');
        const c = parseFloat(nc);
        if (!isNaN(c) && c > 0) {
          if (c >= mingreen && c < maxgreen) {
            row.charge_color = 'green';
          }
          else if (c >= minyellow && c < maxyellow) {
            row.charge_color = 'yellow';
          }
          else if (c >= minred && c <= maxred) {
            row.charge_color = 'red';
          }
        }
      }

      //('matches', data['matches']);

      if (data['matches'].length === 0 && !data['error']) {
        // No charges found, or none of them have payer plan names.
        setLastError('No charges found');
      }

      lastcsvrun = 0; // Force a rebuild of the CSV
      saveChargeMatches(data['matches'], data['error']);
    }
    catch(ex) {
      UtilsService.consoledev('Error fetching charges', ex);

      // Indicate that fetching is done
      saveChargeState('');
    }
    finally {
      setLastMessage('');
    }
  }

  const runchargesasync = async () => {
    const now = Date.now();
    const delta = now - lastchargesrun;
    lastchargesrun = now;

    if (delta < 1000) {
      UtilsService.consoledev('Too close to run charges again', delta);
      return;
    }

    // Run charges if they need to be fetched. The value is pending
    //UtilsService.consoledev('runchargesasync', selectedItem);
    if (chargeState === 'pending' && selectedItem) {
      saveChargeState('fetching');
      setLastMessage('Searching for prices...');

      //setTimeout(() => {
        getcharges();
      //}, 0);
    }
  }
  //const runcharges = useAsyncCallback(runchargesasync);

  //const runchargesnow = (): boolean => { runchargesasync(); return false;}


  const runitemsasync = async () => {
    UtilsService.consoledev('runitemsasync');

    // Run items if they need to be fetched. The value is pending
    if (matchDescriptionSearch.length == 0 && matchCodeSearch.length == 0) {
      return;
    }

    if (!selectedFacility) {
      setLastError('Please select a hospital to see available items');
      return;
    }



    if (matchDescriptionSearch.length < 3 && matchCodeSearch.length < 3) {
      UtilsService.consoledev('item TOO SHORT');
      setLastError('Search must be at least 3 characters long');
      // Name is too short
      return;
    }
    else {
      setLastError('');
    }


    if (itemState == '' || itemState == 'pending') {
      // Retrieve the items
      const facilityid = selectedFacility?.id;

      saveItemState('fetching');
      UtilsService.consoledev('calling charge/itemsearch');
      setOverlayActive(true);
      let data: any = {};
      try {
        data = await serverService.getResponse(`charge/itemsearch?fid=${facilityid}&search=${matchDescriptionSearch}&codesearch=${matchCodeSearch}`);
      }
      finally {
        setOverlayActive(false);
      }

      //UtilsService.consoledev('data', data);
      if (!('matches' in data)) {
        data['matches'] = [];
      }
      UtilsService.consoledev('results', data['matches']?.length, 'errors', data['error']);

      // Analytics
      analyticsService.pageView(`item search: facility=${selectedFacility?.name};${selectedFacility?.city};${selectedFacility?.state},description=${matchDescriptionSearch},code=${matchCodeSearch},raw matches=${data['matches']?.length}`, window.location.href);


      // Rewrite the matches so that the matching code overwrites the primary code
      if (matchCodeSearch.length >= 3) {
        for (let i = 0; i < data['matches'].length; i++) {
          const item = data['matches'][i];
          if (!item.item_code.includes(matchCodeSearch)) {
            // Look in all codes to find the match
            try {
              const codes = JSON.parse(item.item_codes);
              for (const code of codes) {
                let ct = code[0];
                let c = code[1];

                // Replace the separator found in older files
                if (c.includes('‖')) {
                  c = c.replace('‖', ' ');
                }
                if (ct.includes('‖')) {
                  ct = ct.replace('‖', ' ');
                }

                if (c.includes(matchCodeSearch)) {
                  item.item_code_type = ct;
                  item.item_code = c;
                  data['matches'][i] = item;
                  break;
                }
              }
            }
            catch(ex) {
              // Ignore an exeption
            }

          }

        }
      }

      // Sort by code_type to sort CDM to the bottom
      const cdm = data['matches'].filter((x: any) => x.item_code_type === 'CDM');
      const other = data['matches'].filter((x: any) => x.item_code_type !== 'CDM');
      data['matches'] = other.concat(cdm);
       // console.log('item matches', data['matches']);

      saveItemMatches(data['matches'], data['error']);

      // Give feedback to the user
      if (data['error'] && data['error'].length > 0) {
        const err = data['error'];
        if (err.includes('resultsexceeded')) {
          setLastError('Too many results, try narrowing your search');
        }
        else {
          setLastError(err);
        }
      }
      else if (!data['matches'] || data['matches'].length === 0) {
        setLastError('Item not found in the price transparency file');
      }
      else {
        setLastError('');
      }
      //saveMatches(facilitySearch, newdescriptionsearch, newcodesearch, matches, data['matches'], [], data['error']);
    }
  }
  const runitems = useAsyncCallback(runitemsasync);


  const runsearch = async () => {
    UtilsService.consoledev('runsearch', facilitySearch, descriptionSearch, codeSearch);
    //await retrieve(facilitySearch, descriptionSearch, codeSearch);
    await retrieveFacility(facilitySearch);
    await retrieveItem(currentSettings.descriptionSearch, currentSettings.codeSearch);
  }

  const onSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    UtilsService.consoledev('onSave', currentSettings, itemState);
  
    // If no change was made, and there are item results, show the dropdown
    if (!showItems && itemState == 'fetched' && itemMatches && itemMatches.length > 0) {
      UtilsService.consoledev('Not showing items', itemMatches);
      saveShowItems(true); // Show the results
      return;
    }

    // Special case is when the item is entered before a facility is selected.
    
    if (itemState == 'pending' && 
      ((matchDescriptionSearch == currentSettings.descriptionSearch && matchDescriptionSearch.length >= 3) ||
      (matchCodeSearch == currentSettings.codeSearch && matchCodeSearch.length >= 3))
    ) {
      UtilsService.consoledev('itemState is pending. Run items async');
      runitemsasync().then();
    }

    saveSearchParameters(currentSettings.facilitySearch, currentSettings.descriptionSearch, currentSettings.codeSearch);

    
    // saveSearch(currentSettings.facilitySearch, currentSettings.descriptionSearch, currentSettings.codeSearch);



    // Fetch a new record
    //await runsearch();
  };

  const onCancel = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    UtilsService.consoledev('onCancel', currentSettings);
  
    //await cancelrecord(currentSettings.tableid);
    //TODO Submit results
  };

  const clearFacilityValue = async () => {
    UtilsService.consoledev('clearFacilityValue', facilityFilter);
    clearFacility();
    setLastError('');
  }

  const clearItemValue = async () => {
    UtilsService.consoledev('clearItemValue');
    clearItem();
    setLastError('');

    currentSettings.descriptionSearch = '';
    currentSettings.codeSearch = '';
    setCurrentSettings(currentSettings);
  }

  const updateFacilityValue = async (evt: any) => {
    evt.preventDefault();
    const val = evt.target.value;
    setFacilitySearch(val);
    //setSearch(val);
    UtilsService.consoledev('updateInputValue', evt.target.value);
  }
  const updateDescriptionValue = async (evt: any) => {
    evt.preventDefault();
    const val = evt.target.value;
    // UtilsService.consoledev('updated description', val);
    setDescriptionSearch(val);
    saveItemState('pending');
    //setSearch(val);
    //UtilsService.consoledev('updateInputValue', evt.target.value);
  }
  const updateCodeValue = async (evt: any) => {
    evt.preventDefault();
    const val = evt.target.value;
    setCodeSearch(val);
    saveItemState('pending');
    //setSearch(val);
    //UtilsService.consoledev('updateInputValue', evt.target.value);
  }
  const saveclick = useAsyncCallback(onSave);
  const onfacilityupdateclick = useAsyncCallback(updateFacilityValue);
  const onclearfacilityvalueclick = useAsyncCallback(clearFacilityValue);
  const onclearitemclick = useAsyncCallback(clearItemValue);
  const ondescriptionupdateclick = useAsyncCallback(updateDescriptionValue);
  const oncodeupdateclick = useAsyncCallback(updateCodeValue);

  const changeFilterInpatient = async (evt: React.MouseEvent<HTMLInputElement>) => {
    evt.preventDefault();
    let settings = filter_setting.length > 0 ? filter_setting.split(',') : [];
    if (settings.includes('ip')) {
      // Remove ip
      settings = settings.filter(x => x != 'ip');
    }
    else {
      // Add ip
      settings.push('ip');
    }
    saveFilterSetting(settings.join(','));
    UtilsService.consoledev('changeFilterInpatient', settings);  
  }

  const changeFilterOutpatient = async (evt: React.MouseEvent<HTMLInputElement>) => {
    evt.preventDefault();
    let settings = filter_setting.length > 0 ? filter_setting.split(',') : [];
    if (settings.includes('op')) {
      // Remove op
      settings = settings.filter(x => x != 'op');
    }
    else {
      // Add op
      settings.push('op');
    }
    saveFilterSetting(settings.join(','));
    UtilsService.consoledev('changeFilterOutpatient', settings);
  }

  const changeFilterFacility = async (evt: React.MouseEvent<HTMLInputElement>) => {
    evt.preventDefault();
    let settings = filter_billingclass.length > 0 ? filter_billingclass.split(',') : [];
    if (settings.includes('facility')) {
      // Remove facility
      settings = settings.filter(x => x != 'facility');
    }
    else {
      // Add facility
      settings.push('facility');
    }
    saveFilterBillingClass(settings.join(','));
    UtilsService.consoledev('changeFilterFacility', settings);
  }

  const changeFilterProfessional = async (evt: React.MouseEvent<HTMLInputElement>) => {
    evt.preventDefault();
    let settings = filter_billingclass.length > 0 ? filter_billingclass.split(',') : [];
    if (settings.includes('professional')) {
      // Remove professional
      settings = settings.filter(x => x != 'professional');
    }
    else {
      // Add professional
      settings.push('professional');
    }
    saveFilterBillingClass(settings.join(','));
    UtilsService.consoledev('changeFilterProfessional', settings);
  }

  const changeFilterModifier = async (evt: React.MouseEvent<HTMLInputElement>) => {
    evt.preventDefault();
    // Toggle the modifier
    let modifiers = filter_modifiers.length > 0 ? '' : 'modifier';
    saveFilterModifiers(modifiers);
    UtilsService.consoledev('saveFilterModifiers', modifiers);
  }


  //const hascharges = (charge_min.length > 0 || charge_max.length > 0 || charge_gross.length > 0 || charge_cash.length > 0);
  //UtilsService.consoledev('hascharges', hascharges);

  const stateChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    UtilsService.consoledev('stateChange: NOT IMPLEMENTED YET');

    //const value = event.target.value;
    //if (value === searchState) {
    //  return;
    //}

    // Validate it is correct
    //const validate = states.find(x => x[1] === value);
    //if (!validate) {
    //  return;
    //}

  };
  const onstatechange = useAsyncCallback(stateChange);

  function ChangeView({center, zoom, bounds }: any) {
    const map = useMap();
    //console.log('*****map', map);

    if (!center || !zoom || !bounds) {
      map.invalidateSize();
      return null;
    }

    const deltas: number[] = [];
    deltas.push(center.lat - lastcenter.lat);
    deltas.push(center.lng - lastcenter.lng);
    deltas.push(zoom - lastzoom);
    deltas.push(bounds[0][0] - lastbounds[0][0]);
    deltas.push(bounds[0][1] - lastbounds[0][1]);
    deltas.push(bounds[1][0] - lastbounds[1][0]);
    deltas.push(bounds[1][1] - lastbounds[1][1]);

    // If any delta is NaN, return null
    if (deltas.some(isNaN)) {
      map.invalidateSize();
      return null;
    }

    lastcenter = center;
    lastzoom = zoom;
    lastbounds = bounds;

    // Ignore tiny changes
    const maxdelta = Math.max(...deltas);
    if (maxdelta < 0.001) {
      // console.log('Ignoring changeview');
      map.invalidateSize();
      return null;
    }

    //UtilsService.consoledev('*****ChangeView', center, zoom, bounds);


    if (center && zoom && bounds && !isNaN(bounds[0][0])) {
      //UtilsService.consoledev('ChangeView', center, zoom, bounds);
      map.setView(center, zoom);
      //new LatLngBounds(bounds).pad(0.5);
      map.fitBounds(new LatLngBounds(bounds).pad(0.2))
      //map.fitBounds(bounds);
      map.invalidateSize();
    }

    return null;
  }

  // My original interface during development
  const originalInterface = () => {
    if (!sessionid || sessionid.length === 0) {
      return (
        null
      );
    }
  
    if (welcome) {
      return (
        null
      );
    }

    return (
        <main className="main-wrapper">
        <section className="section-title-publicly-availaible-ptf">
          <div className="container-medium">
            <div className="padding-small heading-title-top-padding">
            </div>
            <div className="padding-small moble-header-text">
              <div className="header-text-map-wrapper">
              </div>
            </div>

          </div>
        </section>

        <section className="section-search-field-viewtabs">
          <div className="container-medium">
            <div className="padding-small">
              <div className="search-viewtabs-wrapper">
                <div id="w-node-d8765c65-11ad-846f-f1c1-142c65ab61e5-d5517b60" className="search-primary-state-wrapper">
                  <form action="/search" className="search w-form">
                    <div>
                    <span>Facility Search</span><input type="search" className="search-input w-input" maxLength={256} name="query" placeholder="Search Hospitals, City, zip" id="search" value={facilityFilter} onChange={onfacilityupdateclick.execute}/>
                    </div>

                    <div>
                    <span>Description Search</span><input type="dsearch" className="search-input w-input" maxLength={256} name="query" placeholder="Search Item Description" id="dsearch" value={descriptionSearch} onChange={ondescriptionupdateclick.execute}/>
                    </div>

                    <div>
                    <span>Code Search</span><input type="csearch" className="search-input w-input" maxLength={256} name="query" placeholder="Search Item Code" id="csearch" value={codeSearch} onChange={oncodeupdateclick.execute}/>
                    </div>

                    <div data-hover="false" data-delay="0" className="state-dropdown w-dropdown">
                    </div><button type="submit" value="" className="search-button w-button" onClick={saveclick.execute}/>
                  </form>
                </div>

                <div>
                { selectedFacility &&
                  <span>Selected Facility: {selectedFacility?.name}:&nbsp;{selectedFacility?.city},&nbsp;{selectedFacility?.state}</span> 
                }
                </div>
                <div>
                { selectedItem &&
                  <span>Selected Item: {selectedItem?.item_description}:&nbsp;{selectedItem?.item_code_type},&nbsp;{selectedItem?.item_code}</span> 
                }
                </div>
                <div>
                  { (chargeMatches.length > 0) &&
                  <span>Min charge: {charge_min}&nbsp;Max charge: {charge_max}&nbsp;Gross charge: {charge_gross}&nbsp;Cash charge: {charge_cash}</span>
                  }
                </div>


              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container-medium">Report Here</div>
        </section>

        </main>
    );
  }

  const Example = () => {
    const [referenceElement, setReferenceElement] = useState<HTMLElement|null>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement|null>(null);
    const [arrowElement, setArrowElement] = useState<HTMLElement|null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    });
  
    return (
      <>
        <button type="button" ref={setReferenceElement}>
          Reference element
        </button>
  
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          Popper element
          <div ref={setArrowElement} style={styles.arrow} />
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="ttCode-container">
      <ReactTooltip key="ttCode" id="ttCode" className="ttCode" style={{color: '#FFFFFF', backgroundColor: '#0059A7', padding: 10, margin: 0}} border="#0059A7" />
      </div>
      <section className="section_hero">
        <MySpinner active={overlayactive}>
      <div className="container">
        <h1>{region_name} Hospital Price Finder</h1>
        {(region_name === 'Colorado') && 
          <div>Click <a target="_blank" href="https://youtu.be/EPZv7bMi1qg">here</a> to watch a video on how to use the Colorado Hospital Price Finder tool.</div>
        }
        {(region_name === 'New York City') && 
          <div>Click <a target="_blank" href="https://youtu.be/7f5AdblXpL0">here</a> to watch a video on how to use the New York City Hospital Price Finder tool.</div>
        }

       <div className="background-color-white">
        </div>
        <div className="spacer-18px"></div>
        <div className="form_component w-form">
          <form id="filters-form" name="email-form" data-name="Email Form" method="get" className="form_form" data-wf-page-id="666f2106d12ff507b725730c" data-wf-element-id="902d499f-335d-1423-a66a-0f8fce51e9ad">
            <div className="form_column form_first-item">
              <div className="form_input-container">
                <div data-hover="false" data-delay="0" className="form_dropdown z-index-40 w-dropdown">
                  {/* <div id="hospital-name-dropdown-toggle" className="form_dropdown-toggle city w-dropdown-toggle"> */}
                  <div id="hospital-name-dropdown-toggle" className="form_dropdown-toggle city">
                    <input className="dropdown_input city w-input" type="search" maxLength={256} name="query" data-name="Search-Cities" placeholder="Search Hospitals, City, zip" id="search" value={(selectedFacility && selectedFacility.id) ? selectedFacility.name : facilityFilter} onChange={onfacilityupdateclick.execute}/>
                    { ((selectedFacility && selectedFacility.id) || (facilityFilter && facilityFilter.length > 0)) &&
                    <a onClick={()=>{onclearfacilityvalueclick.execute()}}><MdOutlineClear size={25} className="right-align" /></a>
                    }
                    <div id="hospital-name-label" className="hospital-name-label"></div>
                    <div id="input-field-clear" className="form_dropdown-reset city w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
                        <path d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                      </svg></div><img src="images/ICON_search.svg" loading="lazy" alt="" className="form_input-icon"/>
                  </div>
{ /*
                  <nav id="locations-list" className="form_dropdown-list w-dropdown-list">
                    <div id="recents-container" className="form_recents-container">
                      <div className="form_recents-label">Recent</div>
                      <div id="recents-wrapper" className="form_recents-wrapper"></div>
                    </div>
                    <label className="form_dropdown-link locations_dropdown-link w-radio"><input type="radio" name="Hospital-Name-City-Zip" id="80045" data-name="Hospital Name, City, Zip" className="w-form-formradioinput form_hidden-radio-button w-radio-input" value="80045"/><span className="form_dropdown-radio-button-label w-form-label">80045</span>
                      <div className="dropdown-link_text">Aurora South Hospital and Medical Center</div>
                      <div className="locations_dropdown-link-row"><img loading="lazy" src="images/marker-pin-01.svg" alt="" className="locations_icon"/>
                        <div className="locations_text mg-right-10px">Aurora</div>
                        <div className="locations_text">80045</div>
                      </div>
                    </label>
                    <label className="form_dropdown-link locations_dropdown-link w-radio"><input type="radio" name="Hospital-Name-City-Zip" id="80033" data-name="Hospital Name, City, Zip" className="w-form-formradioinput form_hidden-radio-button w-radio-input" value="80033"/><span className="form_dropdown-radio-button-label w-form-label">80033</span>
                      <div className="dropdown-link_text">Lutheran Medical Center</div>
                      <div className="locations_dropdown-link-row"><img loading="lazy" src="images/marker-pin-01.svg" alt="" className="locations_icon"/>
                        <div className="locations_text mg-right-10px">Wheat Ridge</div>
                        <div className="locations_text">80033</div>
                      </div>
                    </label><label className="form_dropdown-link locations_dropdown-link w-radio"><input type="radio" name="Hospital-Name-City-Zip" id="80229-2" data-name="Hospital Name, City, Zip" className="w-form-formradioinput form_hidden-radio-button w-radio-input" value="80229"/><span className="form_dropdown-radio-button-label w-form-label">80229</span>
                      <div className="dropdown-link_text">North Suburban Medical Center</div>
                      <div className="locations_dropdown-link-row"><img loading="lazy" src="images/marker-pin-01.svg" alt="" className="locations_icon"/>
                        <div className="locations_text mg-right-10px">Thorton</div>
                        <div className="locations_text">80229</div>
                      </div>
                    </label><label className="form_dropdown-link locations_dropdown-link w-radio"><input type="radio" name="Hospital-Name-City-Zip" id="80218" data-name="Hospital Name, City, Zip" className="w-form-formradioinput form_hidden-radio-button w-radio-input" value="80218"/><span className="form_dropdown-radio-button-label w-form-label">80218</span>
                      <div className="dropdown-link_text">Presbyterian St Luke&#x27;s Medical Center</div>
                      <div className="locations_dropdown-link-row"><img loading="lazy" src="images/marker-pin-01.svg" alt="" className="locations_icon"/>
                        <div className="locations_text mg-right-10px">Denver</div>
                        <div className="locations_text">80218</div>
                      </div>
                    </label><label className="form_dropdown-link locations_dropdown-link w-radio"><input type="radio" name="Hospital-Name-City-Zip" id="80220" data-name="Hospital Name, City, Zip" className="w-form-formradioinput form_hidden-radio-button w-radio-input" value="80220"/><span className="form_dropdown-radio-button-label w-form-label">80220</span>
                      <div className="dropdown-link_text">Rose Medical Center</div>
                      <div className="locations_dropdown-link-row"><img loading="lazy" src="images/marker-pin-01.svg" alt="" className="locations_icon"/>
                        <div className="locations_text mg-right-10px">Denver</div>
                        <div className="locations_text">80220</div>
                      </div>
                    </label><label className="form_dropdown-link locations_dropdown-link w-radio"><input type="radio" name="Hospital-Name-City-Zip" id="80124" data-name="Hospital Name, City, Zip" className="w-form-formradioinput form_hidden-radio-button w-radio-input" value="80124"/><span className="form_dropdown-radio-button-label w-form-label">80124</span>
                      <div className="dropdown-link_text">Sky Ridge Medical Center</div>
                      <div className="locations_dropdown-link-row"><img loading="lazy" src="images/marker-pin-01.svg" alt="" className="locations_icon"/>
                        <div className="locations_text mg-right-10px">Lone Tree</div>
                        <div className="locations_text">80124</div>
                      </div>
                    </label><label className="form_dropdown-link locations_dropdown-link w-radio"><input type="radio" name="Hospital-Name-City-Zip" id="80113" data-name="Hospital Name, City, Zip" className="w-form-formradioinput form_hidden-radio-button w-radio-input" value="80113"/><span className="form_dropdown-radio-button-label w-form-label">80113</span>
                      <div className="dropdown-link_text">Swedish Medical Center</div>
                      <div className="locations_dropdown-link-row"><img loading="lazy" src="images/marker-pin-01.svg" alt="" className="locations_icon"/>
                        <div className="locations_text mg-right-10px">Englewood</div>
                        <div className="locations_text">80113</div>
                      </div>
                    </label><label className="form_dropdown-link locations_dropdown-link w-radio"><input type="radio" name="Hospital-Name-City-Zip" id="80000" data-name="Hospital Name, City, Zip" className="w-form-formradioinput form_hidden-radio-button w-radio-input" value="80000"/><span className="form_dropdown-radio-button-label w-form-label">80000</span>
                      <div className="dropdown-link_text">Hospital disambiguation line one </div>
                      <div className="locations_dropdown-link-row"><img loading="lazy" src="images/marker-pin-01.svg" alt="" className="locations_icon"/>
                        <div className="locations_text mg-right-10px">Wheat Ridge</div>
                        <div className="locations_text">80000</div>
                      </div>
                    </label><label className="form_dropdown-link locations_dropdown-link w-radio"><input type="radio" name="Hospital-Name-City-Zip" id="80229" data-name="Hospital Name, City, Zip" className="w-form-formradioinput form_hidden-radio-button w-radio-input" value="80229"/><span className="form_dropdown-radio-button-label w-form-label">80229</span>
                      <div fs-cmsselect-element="text-value" className="dropdown-link_text">North Suburban Medical Center</div>
                      <div className="locations_dropdown-link-row"><img loading="lazy" src="images/marker-pin-01.svg" alt="" className="locations_icon"/>
                        <div className="locations_text mg-right-10px">Thorton</div>
                        <div className="locations_text">80229</div>
                      </div>
                    </label>
                  </nav>
  */ }
                </div>
              </div>
              <div className="form_label">e.g., Adventhealth Porter, Denver, 80210</div>
            </div>
            <div className="form_column form_second-column">
              <div className="form_item">
                <div className="form_input-container">
                  <div data-hover="false" data-delay="0" fs-selectcustom-element="dropdown" className="form_dropdown item-description w-dropdown">
                    { /*<div id="item-description-reset" className="form_dropdown-toggle dropdown-group w-dropdown-toggle">*/}
                    <div id="item-description-reset" className="form_dropdown-toggle dropdown-group">
                      <div className="form_dropdown-container">
                        <input className="dropdown_input city w-input" type="search" maxLength={256} name="query" data-name="Search-Cities" placeholder="Item Description" id="dsearch" value={currentSettings.descriptionSearch} onChange={ondescriptionupdateclick.execute}/>
                        { (currentSettings.descriptionSearch.length > 0) &&
                        <a onClick={()=>{onclearitemclick.execute()}}><MdOutlineClear size={25} className="right-align" /></a>
                        }
{
                        //<div id="item-description-label" className="form_select-label item-description">Item Description</div>
}
                        <div className="form_dropdown-reset item-description w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
                            <path d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                          </svg></div>
                      </div>
                      <div className="form_dropdown-container code">
                        <input className="dropdown_input city w-input" type="search" maxLength={256} name="query" data-name="Search-Cities" placeholder="Code" id="csearch" value={currentSettings.codeSearch} onChange={oncodeupdateclick.execute}/>
                        { (currentSettings.codeSearch.length > 0) &&
                        <a onClick={()=>{onclearitemclick.execute()}}><MdOutlineClear size={25} className="right-align" /></a>
                        }
{
                        //<div id="code-label" className="form_select-label code">Code</div>
}
                        <div className="form_dropdown-reset code w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
                            <path d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                          </svg></div>
                      </div>
                      <button style={{backgroundImage: 'url(images/ICON_search.svg)'}} className="form_input-icon" type="submit" value="" onClick={saveclick.execute}/>
                      {
                      // <img src="images/ICON_search.svg" loading="lazy" alt="" className="form_input-icon" onClick={saveclick.execute}/>
                      }
                    </div>
                    <Items2 />
                  </div>
                </div>
                <div className="form_labels-container">
                  <div className="form_label item-description">Procedures, Drugs, Supplies, e.g., x-ray, colon, splint.&nbsp;
                  <a target="_blank" href="https://patientrightsadvocate.atlassian.net/servicedesk/customer/portal/1/article/37289985">Search tips</a>

                  </div>
                  <div className="form_label divider"> | </div>
                  <div className="form_label code">e.g., 72110, 470</div>
                </div>
              </div>
              <div className="form_more-info-container">
              <a data-tooltip-id="ttCode" data-tooltip-html={`
                                <div class="hover-block" style={{wordWrap: 'normal'}}>
                                <span class="semibold-text-span">CPT</span> codes - Current Procedural Terminology (CPT®) codes describe medical procedures. They are 5-digits and range from 00100–99499.<br/><br/>
                                <span class="semibold-text-span">HCPCS</span> codes - Healthcare Common Procedure Coding System (HCPCS) codes are standardized codes for medical supplies, products and services. They are 5-digits with a letter followed by four numbers, and range from A0000–V9999. <br/><br/>
                                <span class="semibold-text-span">MS-DRG</span> codes - Medicare Severity-Diagnosis Related Group (MS-DRG) codes categorize a Medicare patient&#x27;s inpatient hospital stays based on diagnosis, severity, and resource utilization. They are 3-digits. <br/><br/>
                                <span class="semibold-text-span">APR-DRG</span> codes - All Patient Refined Diagnosis Related Group (APR DRG) codes classify treatments for groups of patients  according to their reason of admission, severity of illness and risk of mortality. They are 4-digits. <br/><br/>
                                <span class="semibold-text-span">NDC</span> codes - National Drug Code (NDC) is a unique 10-digit or 11-digit number that identifies all prescription and nonprescription (OTC) medication, including their packages and inserts. <br/><br/>
                                <span class="semibold-text-span">ICD</span> codes - International Classification of Diseases (ICD) codes are used to specify additional information about the procedures being performed for inpatient hospital stays. They are 9 or 10 digits, often in groups “5 digits-4 digits-2 digits” separated by dashes. <br/><br/>
                                <span class="semibold-text-span">APC</span> - Ambulatory Payment Classification (APC) codes identify outpatient services billed to Medicare. They are 4-digits. <br/><br/>
                                <span class="semibold-text-span">Revenue codes</span> - Revenue Codes exist for every item and service in a hospital’s chargemaster. They are 4-digits and are added to hospital bills to indicate the type or location of the service or item to insurers.
                              </div>
              `}>
              <img src="images/info-circle.svg" loading="lazy" data-w-id="c48a5e4f-f1ab-bd0f-6a5f-92fa8bd7de60" alt="" className="form_more-info-icon open"/>
              </a>
              </div>

              {/*
              <div ref={setPopperCodeElement} style={styles.popper} {...attributes.popper}>
                Popper element
                <div ref={setArrowCodeElement} style={styles.arrow} />
              </div>
              */}
              { /*
              <Popover 
                isOpen={isCodePopoverOpen}
                align="start"
                positions={['bottom', 'left', 'top']}
                padding={10} 
                reposition={false} 
                onClickOutside={()=>setIsCodePopoverOpen(false)}
                content={({position, childRect, popoverRect}) => (
                  <ArrowContainer 
                    position={position} 
                    style={{backgroundColor: '#e0e0e0', padding: 10, borderRadius: 4}}
                    childRect={childRect} 
                    popoverRect={popoverRect} 
                    arrowStyle={{ opacity: 0.7}}
                    className="popover-arrow-container"
                    arrowClassName="popover-arrow"
                    arrowColor={"#2a2a2d"} 
                    arrowSize={8}>
                  <div className="text-block" style={{wordWrap: 'normal'}}>
                    <span className="semibold-text-span">CPT</span> codes - Current Procedural Terminology (CPT®) codes describe medical procedures. They are 5-digits and range from 00100–99499.<br/><br/>
                    <span className="semibold-text-span">HCPCS</span> codes - Healthcare Common Procedure Coding System (HCPCS) codes are standardized codes for medical supplies, products and services. They are 5-digits with a letter followed by four numbers, and range from A0000–V9999. <br/><br/>
                    <span className="semibold-text-span">MS-DRG</span> codes - Medicare Severity-Diagnosis Related Group (MS-DRG) codes categorize a Medicare patient&#x27;s inpatient hospital stays based on diagnosis, severity, and resource utilization. They are 3-digits. <br/><br/>
                    <span className="semibold-text-span">APR-DRG</span> codes - All Patient Refined Diagnosis Related Group (APR DRG) codes classify treatments for groups of patients  according to their reason of admission, severity of illness and risk of mortality. They are 4-digits. <br/><br/>
                    <span className="semibold-text-span">NDC</span> codes - National Drug Code (NDC) is a unique 10-digit or 11-digit number that identifies all prescription and nonprescription (OTC) medication, including their packages and inserts. <br/><br/>
                    <span className="semibold-text-span">ICD</span> codes - International Classification of Diseases (ICD) codes are used to specify additional information about the procedures being performed for inpatient hospital stays. They are 9 or 10 digits, often in groups “5 digits-4 digits-2 digits” separated by dashes. <br/><br/>
                    <span className="semibold-text-span">APC</span> - Ambulatory Payment Classification (APC) codes identify outpatient services billed to Medicare. They are 4-digits. <br/><br/>
                    <span className="semibold-text-span">Revenue codes</span> - Revenue Codes exist for every item and service in a hospital’s chargemaster. They are 4-digits and are added to hospital bills to indicate the type or location of the service or item to insurers.
                  </div>
                </ArrowContainer>
                )}
              >
                <img src="images/info-circle.svg" loading="lazy" onClick={() => setIsCodePopoverOpen(!isCodePopoverOpen)} style={{opacity:1}} data-w-id="c48a5e4f-f1ab-bd0f-6a5f-92fa8bd7de60" alt="" className="form_more-info-icon open"/>
              </Popover>
                </div>
              */} 
{/*
              <div className="form_more-info-container" onMouseEnter={()=>handleMouseOver()} onMouseLeave={()=>handleMouseOut()}><img src="images/info-circle-1.svg" loading="lazy" style={{opacity:0}} alt="" className="form_more-info-icon close"/>
                <img src="images/info-circle.svg" loading="lazy" style={{opacity:1}} data-w-id="c48a5e4f-f1ab-bd0f-6a5f-92fa8bd7de60" alt="" className="form_more-info-icon open"/>
                {isHovering && (
                <div>
                  <div className="text-block">
                    <span className="semibold-text-span">CPT</span> codes - Current Procedural Terminology (CPT®) codes describe medical procedures. They are 5-digits and range from 00100–99499.<br/><br/>
                    <span className="semibold-text-span">HCPCS</span> codes - Healthcare Common Procedure Coding System (HCPCS) codes are standardized codes for medical supplies, products and services. They are 5-digits with a letter followed by four numbers, and range from A0000–V9999. <br/><br/>
                    <span className="semibold-text-span">MS-DRG</span> codes - Medicare Severity-Diagnosis Related Group (MS-DRG) codes categorize a Medicare patient&#x27;s inpatient hospital stays based on diagnosis, severity, and resource utilization. They are 3-digits. <br/><br/>
                    <span className="semibold-text-span">APR-DRG</span> codes - All Patient Refined Diagnosis Related Group (APR DRG) codes classify treatments for groups of patients  according to their reason of admission, severity of illness and risk of mortality. They are 4-digits. <br/><br/>
                    <span className="semibold-text-span">NDC</span> codes - National Drug Code (NDC) is a unique 10-digit or 11-digit number that identifies all prescription and nonprescription (OTC) medication, including their packages and inserts. <br/><br/>
                    <span className="semibold-text-span">ICD</span> codes - International Classification of Diseases (ICD) codes are used to specify additional information about the procedures being performed for inpatient hospital stays. They are 9 or 10 digits, often in groups “5 digits-4 digits-2 digits” separated by dashes. <br/><br/>
                    <span className="semibold-text-span">APC</span> - Ambulatory Payment Classification (APC) codes identify outpatient services billed to Medicare. They are 4-digits. <br/><br/>
                    <span className="semibold-text-span">Revenue codes</span> - Revenue Codes exist for every item and service in a hospital’s chargemaster. They are 4-digits and are added to hospital bills to indicate the type or location of the service or item to insurers.
                  </div>
                </div>
                )}
              </div>
              */}
            </div>
          </form>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
        { lasterror && lasterror.length > 0 &&
        <div className="text-align-center" style={{color:'red'}}>{lasterror}</div>
        }
        { lastmessage && lastmessage.length > 0 &&
        <div className="text-align-center" style={{color:'red'}}>{lastmessage}</div>
        }
{ !(chargeMatches.length > 0) &&
        <div>
        <h2 id="h2-title-info" className="text-align-center">To see prices, select a Hospital, Item, and Code.</h2>
        <div className="spacer-small"></div>
        <div id="map-container" className="map">
          <div className="map_first-column">
            <div className="form-block-map w-form">
              <form id="email-form-3" name="email-form-3" data-name="Email Form 3" method="get" className="form" data-wf-page-id="666f2106d12ff507b725730c" data-wf-element-id="d2457a92-7bef-3831-9fe9-14da9fa47c6f">
                <div data-hover="false" data-delay="0" className="form_dropdown_map w-dropdown">
                  {/*<div className="form_dropdown-toggle city map w-dropdown-toggle"></div>*/}
                  <div className="form_dropdown-toggle city map"></div>
                  <nav className="form_dropdown-list visible w-dropdown-list">
                    <Locations />
                  </nav>
                </div>
              </form>
              <div className="hidden-message w-form-done"></div>
              <div className="hidden-message w-form-fail"></div>
            </div>
{ /*
            <a href="#" className="map_link hidden w-inline-block">
              <div>Aurora South Hospital and Medical Center</div>
              <div className="locations_dropdown-link-row"><img src="images/marker-pin-01.svg" loading="lazy" alt="" className="locations_icon"/>
                <div className="locations_text mg-right-10px">Aurora</div>
                <div className="locations_text secondary_list_id">80045</div>
              </div>
            </a>
            <a href="#" className="map_link hidden w-inline-block">
              <div>Lutheran Medical Center</div>
              <div className="locations_dropdown-link-row"><img src="images/marker-pin-01.svg" loading="lazy" alt="" className="locations_icon"/>
                <div className="locations_text mg-right-10px">Wheat Ridge</div>
                <div className="locations_text secondary_list_id">80033</div>
              </div>
            </a>
            <a href="#" className="map_link hidden w-inline-block">
              <div>North Suburban Medical Center</div>
              <div className="locations_dropdown-link-row"><img src="images/marker-pin-01.svg" loading="lazy" alt="" className="locations_icon"/>
                <div className="locations_text mg-right-10px">Thorton</div>
                <div className="locations_text secondary_list_id">80229</div>
              </div>
            </a>
            <a href="#" className="map_link hidden w-inline-block">
              <div>Aurora South Hospital and Medical Center</div>
              <div className="locations_dropdown-link-row"><img src="images/marker-pin-01.svg" loading="lazy" alt="" className="locations_icon"/>
                <div className="locations_text mg-right-10px">Aurora</div>
                <div className="locations_text secondary_list_id">80045</div>
              </div>
            </a>
            <a href="#" className="map_link hidden w-inline-block">
              <div>Lutheran Medical Center</div>
              <div className="locations_dropdown-link-row"><img src="images/marker-pin-01.svg" loading="lazy" alt="" className="locations_icon"/>
                <div className="locations_text mg-right-10px">Wheat Ridge</div>
                <div className="locations_text secondary_list_id">80033</div>
              </div>
            </a>
            <a href="#" className="map_link hidden w-inline-block">
              <div>North Suburban Medical Center</div>
              <div className="locations_dropdown-link-row"><img src="images/marker-pin-01.svg" loading="lazy" alt="" className="locations_icon"/>
                <div className="locations_text mg-right-10px">Thorton</div>
                <div className="locations_text secondary_list_id">80229</div>
              </div>
            </a>
            <a href="#" className="map_link hidden w-inline-block">
              <div>Aurora South Hospital and Medical Center</div>
              <div className="locations_dropdown-link-row"><img src="images/marker-pin-01.svg" loading="lazy" alt="" className="locations_icon"/>
                <div className="locations_text mg-right-10px">Aurora</div>
                <div className="locations_text secondary_list_id">80045</div>
              </div>
            </a>
            <a href="#" className="map_link hidden w-inline-block">
              <div>Lutheran Medical Center</div>
              <div className="locations_dropdown-link-row"><img src="images/marker-pin-01.svg" loading="lazy" alt="" className="locations_icon"/>
                <div className="locations_text mg-right-10px">Wheat Ridge</div>
                <div className="locations_text secondary_list_id">80033</div>
              </div>
            </a>
            <a href="#" className="map_link hidden w-inline-block">
              <div>North Suburban Medical Center</div>
              <div className="locations_dropdown-link-row"><img src="images/marker-pin-01.svg" loading="lazy" alt="" className="locations_icon"/>
                <div className="locations_text mg-right-10px">Thorton</div>
                <div className="locations_text secondary_list_id">80229</div>
              </div>
            </a>
            <a href="#" className="map_link hidden w-inline-block">
              <div>Aurora South Hospital and Medical Center</div>
              <div className="locations_dropdown-link-row"><img src="images/marker-pin-01.svg" loading="lazy" alt="" className="locations_icon"/>
                <div className="locations_text mg-right-10px">Aurora</div>
                <div className="locations_text secondary_list_id">80045</div>
              </div>
            </a>
*/ }
          </div>
          <div className="map_container">
            <div className="map_embed w-embed" style={{display: showItems ? 'none' : 'block'}}>
              { !isNaN(centerlat) && !isNaN(centerlong) && bounds &&
              <ErrorBoundary FallbackComponent={fallback} onReset={(details)=> {
                //console.log('resetting the map', details);
                <ChangeView center={{ lat: centerlat, lng: centerlong }} bounds={bounds} zoom={zoom} />
              }}>
              <MapContainer style={{maxHeight: '480px', maxWidth: '960px'}} center={{ lat: centerlat, lng: centerlong }} zoom={zoom}>
                <TrackMapEvents />
              <ChangeView center={{ lat: centerlat, lng: centerlong }} bounds={bounds} zoom={zoom} />

              <TileLayer
                attribution=''
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <>
              {filteredMatches.map((facility:IFacilityInterface) => (
                facility.lat && facility.long && !isNaN(parseFloat(facility.lat)) && !isNaN(parseFloat(facility.long)) &&
                <Marker key={uuidv4()} 
                  position={[parseFloat(facility.lat || defaultlat.toString()), parseFloat(facility.long || defaultlong.toString())]}
                  icon={stars(facility.cmsrating, iconzoom)}
                >
                  <Popup>
                    <Link to="#" onClick={() => onSelectFacility(facility.id)}>{facility.name}</Link><br/>
                    {starsmap(facility.cmsrating)}
                    {facility.address}<br/>
                    {facility.city}, {facility.state} {facility.zip}
                  </Popup>
                </Marker>
                
              ))}
              </>
              </MapContainer>
              </ErrorBoundary>
              }
            </div>
          </div>
        </div>
        </div>
}
{ (chargeMatches.length > 0) &&
        <div id="results-table" className="results">
          <div className="results_filters-form-component w-form">
            <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" className="results_form-form" data-wf-page-id="666f2106d12ff507b725730c" data-wf-element-id="5a6a69b9-2488-41de-adca-1e3c66762d7f">
              <div className="text-size-medium text-weight-semibold text-color-gray-700 hide-portrait">Filters&nbsp;
              <a data-tooltip-id="ttCode" data-tooltip-html={`
                                <div class="hover-block" style={{wordWrap: 'normal'}}>
                                <span class="semibold-text-span">Inpatient</span> is care you receive when admitted to a hospital for overnight stay or an extended period.
                                <br/>
                                <span class="semibold-text-span">Outpatient</span> is care that you receive without being admitted or for a stay of less than 24 hours.
                                <br/><br/>
                                <span class="semibold-text-span">Facility</span> fees are for services rendered within a hospital facility.
                                <br/>
                                <span class="semibold-text-span">Professional</span> fees are for services provided by individual healthcare providers.
                                <br/><br/>
                                <span class="semibold-text-span">Modifiers</span> are additional codes corresponding to hospital items or services that change the standard charge. Not all items have modifiers.
                              </div>
              `}>
              <img src="images/info-circle.svg" loading="lazy" data-w-id="c48a5e4f-f1ab-bd0f-6a5f-92fa8bd7de60" alt="" className="form_more-info-icon open"/>
              </a>
              </div>
              <div className="center-start-flex gap-46px gap-7px-portrait">
              <div className={`radio-buttons_group align-stretch ${hasSetting ? '' : 'disabled'}`}>
                  <label className="results_radio-button-field w-radio">
                    <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom results_radio-button w-radio-input ${filter_setting.includes('ip') ? 'w--redirected-checked' : ''}`}></div>
                    <input type="checkbox" data-name="Patien" id="IN-Patient" name="Patien" disabled={!hasSetting} style={{opacity:0, position:'absolute',zIndex:-1}} checked={filter_setting.includes('ip')} onChange={evt => {}} onClick={changeFilterInpatient} value="IN Patient"/>
                    <span className="results_radio-button-label w-form-label">IN Patient</span>
                  </label>
                  <label className="results_radio-button-field w-radio">
                    <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom results_radio-button w-radio-input ${filter_setting.includes('op') ? 'w--redirected-checked' : ''}`}></div>
                    <input type="checkbox" name="Patien" id="Out-Patient" data-name="Patien" disabled={!hasSetting} style={{opacity:0,position:'absolute',zIndex:-1}} checked={filter_setting.includes('op')} onChange={evt => {}} onClick={changeFilterOutpatient} value="Out Patient"/>
                    <span className="results_radio-button-label w-form-label">OUT Patient</span>
                  </label>
                </div>
                <div className={`radio-buttons_group align-stretch ${hasBillingClass ? '' : 'disabled'}`}>
                  <label className="results_radio-button-field _88px-portrain w-radio">
                    <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom results_radio-button w-radio-input ${filter_billingclass.includes('facility') ? 'w--redirected-checked' : ''}`}></div>
                    <input type="checkbox" name="Facility-Professional" id="Facility" data-name="Facility/Professional" disabled={!hasBillingClass} style={{opacity:0,position:'absolute',zIndex:-1}} checked={filter_setting.includes('facility')} onChange={evt => {}} onClick={changeFilterFacility} value="Facility"/>
                    <span className="results_radio-button-label w-form-label">Facility</span>
                  </label>
                  <label className="results_radio-button-field _88px-portrain w-radio">
                    <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom results_radio-button w-radio-input ${filter_billingclass.includes('professional') ? 'w--redirected-checked' : ''}`}></div>
                    <input type="checkbox" name="Facility-Professional" id="Professional" data-name="Facility/Professional" disabled={!hasBillingClass} style={{opacity:0,position:'absolute',zIndex:-1}} value="Professional" checked={filter_setting.includes('professional')} onChange={evt => {}} onClick={changeFilterProfessional}/>
                    <span className="results_radio-button-label w-form-label">Professional</span>
                  </label>
                </div>
                <div className={`radio-buttons_group align-stretch ${hasModifiers ? '' : 'disabled'}`}>
                  <label className="results_radio-button-field _88px-portrain w-radio">
                    <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom results_radio-button w-radio-input ${filter_modifiers.includes('modifier') ? 'w--redirected-checked' : ''}`}></div>
                    <input type="checkbox" name="Modifier" id="Modifier" data-name="Modifier" disabled={!hasModifiers} style={{opacity:0,position:'absolute',zIndex:-1}} checked={filter_modifiers.includes('modifier')} onChange={evt => {}} onClick={changeFilterModifier} value="Modifier"/>
                    <span className="results_radio-button-label w-form-label">Modifiers</span>
                  </label>
                </div>
                <div>
                  <a target="_blank" href="https://patientrightsadvocate.atlassian.net/servicedesk/customer/portal/1/topic/345ddfa4-be92-4bd5-bd03-782486210d44/article/38240258">help</a>
                </div>

              </div>
              { /*
              <div className="results_more-filters">
                <div className="more-filters_hide-show-group">
                  <div data-w-id="01f0e2af-b273-1164-58a3-cf8a830182ec" style={{display:'none'}} className="more-filters_hide-text">hide</div>
                  <div data-w-id="588b64e7-8a98-36e4-15d6-fb1637834773" style={{display:'block'}} className="more-filters_more-text">more filters</div>
                </div>
                <div style={{display:'none'}} className="more-filters-group">
                  <div className="text-size-medium text-weight-medium text-size-xsmall-portrait">Modifiers</div>
                  <div className="results_checkbox-group"><label className="w-checkbox results_checkbox-field">
                      <div className="w-checkbox-input w-checkbox-input--inputType-custom results_checkbox"></div><input type="checkbox" id="LT" name="LT" data-name="LT" style={{opacity:0,position:'absolute',zIndex:-1}}/><span className="results_checkbox-label w-form-label">LT</span>
                    </label><label className="w-checkbox results_checkbox-field">
                      <div className="w-checkbox-input w-checkbox-input--inputType-custom results_checkbox"></div><input type="checkbox" name="GZ" id="GZ" data-name="GZ" style={{opacity:0,position:'absolute',zIndex:-1}}/><span className="results_checkbox-label w-form-label">GZ</span>
                    </label><label className="w-checkbox results_checkbox-field">
                      <div className="w-checkbox-input w-checkbox-input--inputType-custom results_checkbox"></div><input type="checkbox" name="50" id="50" data-name="50" style={{opacity:0,position:'absolute',zIndex:-1}}/><span className="results_checkbox-label w-form-label">50</span>
                    </label></div>
                </div>
              </div>
               */}
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>

          <div className="results_titles-row">
            <div className="results_title-column-1">
              <div className="results_results-counter">{chargeMatches ? chargeMatches.length : ''}</div>
              <div className="text-size-large text-weight-semibold text-color-blue-dark-950">
                <a data-tooltip-id="ttCode" data-tooltip-html={`
                                    <div class="hover-block" style={{wordWrap: 'normal'}}>
                                    The prices displayed here are from the hospital price transparency file. Because these files change frequently, please look at the price transparency file to confirm data.
                                    </div>
                `}>
                Results
                </a>
              </div>
              {!showgraph &&
                <a onClick={() =>{saveShowGraph(true)}} className="results_show-graph">show graph</a>
              }
              {showgraph &&
                <a onClick={() =>{saveShowGraph(false)}} className="results_hide-graph">hide graph</a>
              }
              { /*
              <div data-w-id="40a5975e-66c3-544a-df99-1628e30b1081" style={{display:'none'}} className="results_show-graph">show graph</div>
              <div data-w-id="497995bf-024d-688a-bc61-6db0030cd534" style={{display:'block'}} className="results_hide-graph">hide graph</div>
              */}
            </div>
            <div className="results_title-column-2">
              <div className="results_title-container">
                <div className="text-size-big text-weight-medium additional-info-hover">
                <a data-tooltip-id="ttCode" data-tooltip-html={`
                  <div class="hover-block2">The <span class="semibold-text-span">gross charge</span> is the charge for an individual item or service that is reflected on a hospital’s chargemaster, absent any discounts.<br/></div>
              `}> Gross Charge</a></div>
                <div className="text-size-medium text-weight-medium text-color-blue-light-900">{charge_gross ? charge_gross : 'N/A'}</div>
              </div>
              <div className="results_title-container">
                <div className="text-size-big text-weight-medium additional-info-hover">
                  <a data-tooltip-id="ttCode" data-tooltip-html={`
                    <div class="hover-block2">The <span class="semibold-text-span">discounted cash price</span> is the charge when an individual pays cash, or cash equivalent, for a hospital item or service.<br/></div>
                `}> Discounted Cash</a></div>
                <div className="text-size-medium text-weight-medium text-color-blue-light-900">{charge_cash ? charge_cash : 'N/A'}</div>
              </div>
              <div className="results_title-container">
                <div className="text-size-big text-weight-medium additional-info-hover">
                    <a data-tooltip-id="ttCode" data-tooltip-html={`
                      <div class="hover-block2">The <span class="semibold-text-span">de-identified minimum negotiated charge</span> is the lowest charge that a hospital has negotiated with all third-party payers for an item or service.<br/></div>
                  `}> Min</a></div>
                <div className="text-size-medium text-weight-medium text-color-blue-light-900">{charge_min ? charge_min : 'N/A'}</div>
              </div>
              <div className="results_title-container">
                <div className="text-size-big text-weight-medium additional-info-hover">
                    <a data-tooltip-id="ttCode" data-tooltip-html={`
                      <div class="hover-block2">The <span class="semibold-text-span">de-identified maximum negotiated charge</span> is the highest charge that a hospital has negotiated with all third-party payers for an item or service.<br/></div>
                  `}> Max</a></div>
                <div className="text-size-medium text-weight-medium text-color-blue-light-900">{charge_max ? charge_max : 'N/A'}</div>
              </div>
            </div>
            <div className="results_download-block">
              {
              <a target="_blank" href={selectedFacility?.praurl ? selectedFacility?.praurl : selectedFacility?.pageurl} className="results_download-title-container w-inline-block" data-tooltip-id="ttCode" data-tooltip-html={`
              <div class="hover-block2">Click to view the price transparency file details.</div>
          `}>
                <div className="text-size-small text-color-utility-blue-700 text-decoration-underline">Price Transparency file</div>
                <div className="text-size-tiny text-weight-medium">&nbsp;
                {/*
                Updated {selectedFacility?.lastupdated}
                */}
                </div>
              </a>
              }
              <div className="results_download-divider"></div>
              { chargeHeaders && chargeData && chargeHeaders.length >= 0 && chargeData.length >= 0 &&
              <CSVLink data={JSON.parse(chargeData)} headers={JSON.parse(chargeHeaders)} filename={`${new Date().toISOString().slice(0, 10).replace(/-/g, '')}_${selectedFacility?.name.replace(/\s+/g,'_')}_${selectedItem?.item_code}.csv`}>
                <div className="results_download-title-container w-inline-block">
                  <div className="text-size-small text-color-utility-blue-700 text-decoration-underline">
                  <a data-tooltip-id="ttCode" data-tooltip-html={`
                    <div class="hover-block2">Click to download a .csv file of these search results.<br/></div>
                `}>My Results (csv)</a></div>
                  <div className="text-size-tiny text-weight-medium" style={{textDecoration: 'none'}}>&nbsp;</div>
                </div>
              </CSVLink>
              }
            </div>
          </div>

          <div>
            <Charges />
          </div>
{ /*
          <div className="results_titles-row">
            <div className="results_title-column-1">
              <div className="results_results-counter">13</div>
              <div className="text-size-large text-weight-semibold text-color-blue-dark-950">Results</div>
              {/*
              <div data-w-id="40a5975e-66c3-544a-df99-1628e30b1081" style={{display:'none'}} className="results_show-graph">show graph</div>
              <div data-w-id="497995bf-024d-688a-bc61-6db0030cd534" style={{display:'block'}} className="results_hide-graph">hide graph</div>
            </div>
            <div className="results_title-column-2">
              <div className="results_title-container">
                <div className="text-size-big text-weight-medium additional-info-hover">Gross Charge</div>
                <div className="results_more-info">
                  <div className="text-block">The gross charge is the charge for an individual item or service that is reflected on a hospital’s chargemaster, absent any discounts.<br/></div>
                </div>
                <div className="text-size-medium text-weight-medium text-color-blue-light-900">{charge_gross ? charge_gross : 'N/A'}</div>
              </div>
              <div className="results_title-container">
                <div className="text-size-big text-weight-medium additional-info-hover">Discounted Cash</div>
                <div className="results_more-info">
                  <div className="text-block">The gross charge is the charge for an individual item or service that is reflected on a hospital’s chargemaster, absent any discounts.<br/></div>
                </div>
                <div className="text-size-medium text-weight-medium text-color-blue-light-900">{charge_cash ? charge_cash : 'N/A'}</div>
              </div>
              <div className="results_title-container">
                <div className="text-size-big text-weight-medium additional-info-hover">Min</div>
                <div className="results_more-info">
                  <div className="text-block">The gross charge is the charge for an individual item or service that is reflected on a hospital’s chargemaster, absent any discounts.<br/></div>
                </div>
                <div className="text-size-medium text-weight-medium text-color-blue-light-900">{charge_min ? charge_min : 'N/A'}</div>
              </div>
              <div className="results_title-container">
                <div className="text-size-big text-weight-medium additional-info-hover">Max</div>
                <div className="results_more-info">
                  <div className="text-block">The gross charge is the charge for an individual item or service that is reflected on a hospital’s chargemaster, absent any discounts.<br/></div>
                </div>
                <div className="text-size-medium text-weight-medium text-color-blue-light-900">{charge_max ? charge_max : 'N/A'}</div>
              </div>
            </div>
            <div className="results_download-block">
              <a href="#" className="results_download-title-container w-inline-block">
                <div className="text-size-small text-color-utility-blue-700 text-decoration-underline">Price Transparency file</div>
                <div className="text-size-tiny text-weight-medium">Updated 4/23/24</div>
              </a>
              <div className="results_download-divider"></div>
              <a href="#" className="results_download-icon w-inline-block"><img src="images/CSV-Download-Icon.svg" loading="lazy" alt="csv download" className="results_download-icon"/></a>
            </div>
          </div>
          <div style={{display:'block'}} className="results_graph-container">
            <div className="results_graph-track">
              <div className="results_graph-pin first"></div>
              <div className="results_graph-pin second"></div>
              <div className="results_graph-pin third"></div>
              <div className="results_graph-pin fourth"></div>
              <div className="results_graph-pin fifth"></div>
              <div className="results_graph-pin sixth"></div>
              <div className="results_graph-pin seventh"></div>
              <div className="results_graph-pin nineth"></div>
              <div className="results_graph-pin tenth"></div>
              <div className="results_graph-pin eleventh"></div>
              <div className="results_graph-pin twelveth"></div>
            </div>
            <div className="results_price-track">
              <div className="results_price-mark">
                <div className="results_price-scale"></div>
                <div className="text-size-tiny text-weight-medium text-color-utility-gray-700">$1,756</div>
              </div>
              <div className="results_price-mark align-center">
                <div className="results_price-scale"></div>
                <div className="text-size-tiny text-weight-medium text-color-utility-gray-700">$3,456</div>
              </div>
              <div className="results_price-mark align-center">
                <div className="results_price-scale"></div>
                <div className="text-size-tiny text-weight-medium text-color-utility-gray-700">$4,456</div>
              </div>
              <div className="results_price-mark align-end">
                <div className="results_price-scale"></div>
                <div className="text-size-tiny text-weight-medium text-color-utility-gray-700">$4,656</div>
              </div>
            </div>
          </div>
          <div className="results_rows-divider"></div>
          <div className="results_table">
            <div className="results_table-titles-row">
              <div className="results_table-title-column-1">
                <div className="text-size-medium text-weight-semibold">Payer</div>
                <div id="Prayer-Sort-Button" className="results_table-sort">
                  <div className="results_table-sort-icon asc w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 12 7" fill="none">
                      <path d="M1 6L6 1L11 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg></div>
                  <div className="results_table-sort-icon desc w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 12 7" fill="none">
                      <path d="M11 1L6 6L1 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg></div>
                </div>
              </div>
              <div className="results_table-title-column-2">
                <div className="text-size-medium text-weight-semibold">Plan</div>
                <div id="Plan-Sort-Button" className="results_table-sort">
                  <div className="results_table-sort-icon asc w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 12 7" fill="none">
                      <path d="M1 6L6 1L11 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg></div>
                  <div className="results_table-sort-icon desc w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 12 7" fill="none">
                      <path d="M11 1L6 6L1 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg></div>
                </div>
              </div>
              <div className="results_table-title-column-3">
                <div className="text-size-medium text-weight-semibold">Negotiated Charge</div>
                <div id="Negotiated-Charge-Sort-Button" className="results_table-sort">
                  <div className="results_table-sort-icon asc w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 12 7" fill="none">
                      <path d="M1 6L6 1L11 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg></div>
                  <div className="results_table-sort-icon desc w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 12 7" fill="none">
                      <path d="M11 1L6 6L1 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg></div>
                </div>
              </div>
            </div>
            <div className="table-form_component w-form">
              <form id="wf-form-results-form" name="wf-form-results-form" data-name="results-form" method="get" className="table-form_form" data-wf-page-id="666f2106d12ff507b725730c" data-wf-element-id="0a382970-f32d-bc7c-33f4-be287d3203b8">
                <div id="results-form" className="table-form_container">
                  <div className="results_table-row"><label className="w-checkbox results_bookmark-checkbox-field">
                      <div className="w-checkbox-input w-checkbox-input--inputType-custom results_bookmark"></div><input type="checkbox" name="Cigna-of-CO" id="Cigna-of-CO" data-name="Cigna of CO" style={{opacity:0,position:'absolute',zIndex:-1}}/><span className="hidden-label w-form-label">Cigna of CO</span>
                    </label>
                    <div className="portrait-results-title">Payer</div>
                    <div className="results_table-column-1 text-size-xsmall text-color-gray text-color-555-portrait">Cigna of CO</div>
                    <div className="results_table-column-2">
                      <div className="portrait-results-title">Plan</div>
                      <div className="text-size-xsmall text-color-gray text-color-555-portrait">Cigna of CO</div>
                    </div>
                    <div className="results_table-column-3">
                      <div className="results_table-negotiated-charge background-color-green-50">
                        <div className="portrait-results-title text-weight-semibold">Negotiated Charge</div>
                        <div className="text-size-xsmall text-weight-medium text-color-black negotiated-charge">$2,456</div>
                      </div>
                      <div className="results_more-info-block"></div>
                    </div>
                  </div>
                  <div className="results_table-row"><label className="w-checkbox results_bookmark-checkbox-field">
                      <div className="w-checkbox-input w-checkbox-input--inputType-custom results_bookmark"></div><input type="checkbox" name="Anthem" id="Anthem" data-name="Anthem" style={{opacity:0,position:'absolute',zIndex:-1}}/><span className="hidden-label w-form-label">Anthem</span>
                    </label>
                    <div className="portrait-results-title">Payer</div>
                    <div className="results_table-column-1 text-size-xsmall text-color-gray text-color-555-portrait">Anthem</div>
                    <div className="results_table-column-2">
                      <div className="portrait-results-title">Plan</div>
                      <div className="text-size-xsmall text-color-gray text-color-555-portrait">Anthem HMO/PPO/POS</div>
                    </div>
                    <div className="results_table-column-3">
                      <div className="results_table-negotiated-charge background-color-green-50">
                        <div className="portrait-results-title text-weight-semibold">Negotiated Charge</div>
                        <div className="text-size-xsmall text-weight-medium text-color-black negotiated-charge">$2,556</div>
                      </div>
                      <div className="results_more-info-block"></div>
                    </div>
                  </div>
                  <div className="results_table-row"><label className="w-checkbox results_bookmark-checkbox-field">
                      <div className="w-checkbox-input w-checkbox-input--inputType-custom results_bookmark"></div><input type="checkbox" name="Peak-Health-Alliance" id="Peak-Health-Alliance" data-name="Peak Health Alliance" style={{opacity:0,position:'absolute',zIndex:-1}}/><span className="hidden-label w-form-label">Cigna of CO</span>
                    </label>
                    <div className="portrait-results-title">Payer</div>
                    <div className="results_table-column-1 text-size-xsmall text-color-gray text-color-555-portrait">Peak Health Alliance</div>
                    <div className="results_table-column-2">
                      <div className="portrait-results-title">Plan</div>
                      <div className="text-size-xsmall text-color-gray text-color-555-portrait">Peak Health Alliance</div>
                    </div>
                    <div className="results_table-column-3">
                      <div className="results_table-negotiated-charge">
                        <div className="portrait-results-title text-weight-semibold">Negotiated Charge</div>
                        <div className="text-size-xsmall text-weight-medium text-color-black negotiated-charge">38.2%</div>
                      </div>
                      <div className="results_more-info-block"><img loading="lazy" src="images/info-circle.svg" alt="" className="results_more-info-icon"/>
                        <div className="results_table-more-info">
                          <div className="text-size-medium text-color-utility-gray-500">Additional Information</div>
                          <div className="text-weight-medium text-color-black">38.2% of Billable Gross Charges<br/></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="results_table-row"><label className="w-checkbox results_bookmark-checkbox-field">
                      <div className="w-checkbox-input w-checkbox-input--inputType-custom results_bookmark"></div><input type="checkbox" name="UnitedHealthOne-of-CO" id="UnitedHealthOne-of-CO" data-name="UnitedHealthOne of CO" style={{opacity:0,position:'absolute',zIndex:-1}}/><span className="hidden-label w-form-label">Cigna of CO</span>
                    </label>
                    <div className="portrait-results-title">Payer</div>
                    <div className="results_table-column-1 text-size-xsmall text-color-gray text-color-555-portrait">UnitedHealthOne of CO</div>
                    <div className="results_table-column-2">
                      <div className="portrait-results-title">Plan</div>
                      <div className="text-size-xsmall text-color-gray text-color-555-portrait">UnitedHealthOne of CO</div>
                    </div>
                    <div className="results_table-column-3">
                      <div className="results_table-negotiated-charge background-color-yellow-25">
                        <div className="portrait-results-title text-weight-semibold">Negotiated Charge</div>
                        <div className="text-size-xsmall text-weight-medium text-color-black negotiated-charge">$3,456</div>
                      </div>
                      <div className="results_more-info-block"></div>
                    </div>
                  </div>
                  <div className="results_table-row"><label className="w-checkbox results_bookmark-checkbox-field">
                      <div className="w-checkbox-input w-checkbox-input--inputType-custom results_bookmark"></div><input type="checkbox" name="Kaiser-Permanente-of-CO" id="Kaiser-Permanente-of-CO" data-name="Kaiser Permanente of CO" style={{opacity:0,position:'absolute',zIndex:-1}}/><span className="hidden-label w-form-label">Cigna of CO</span>
                    </label>
                    <div className="portrait-results-title">Payer</div>
                    <div className="results_table-column-1 text-size-xsmall text-color-gray text-color-555-portrait">Kaiser Permanente of CO</div>
                    <div className="results_table-column-2">
                      <div className="portrait-results-title">Plan</div>
                      <div className="text-size-xsmall text-color-gray text-color-555-portrait">Kaiser Permanente of CO</div>
                    </div>
                    <div className="results_table-column-3">
                      <div className="results_table-negotiated-charge background-color-red-50">
                        <div className="portrait-results-title text-weight-semibold">Negotiated Charge</div>
                        <div className="text-size-xsmall text-weight-medium text-color-black negotiated-charge">$4,456</div>
                      </div>
                      <div className="results_more-info-block"></div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
            <div className="pagination-container">
              <div className="paginationjs-size-changer">
                <div>Show</div>
                <div className="gap-5px center-center-flex">
                  <div id="size-changer-50" className="text-color-gray-400">50</div>
                  <div className="results_pages-divider"></div>
                  <div id="size-changer-100" className="text-weight-medium">100</div>
                </div>
              </div>
            </div>
          </div>
*/ }
        </div>
}
      </div>
      </MySpinner>
    </section>

    </div>


  );
}

export default FacilitySearch;